import { useNavigate } from "react-router-dom";
import { SolidBtn } from "../components/ui/buttons";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex items-center py-12 px-6 md:px-16">
        <img
          src="/assets/images/logo.png"
          alt="Landing"
          className="h-full w-10 rounded object-cover"
        />
        <h2 className="ml-2 text-xs font-bold uppercase leading-4">
          <span>National</span> <br />
          <span>Insurance</span> <br />
          <span>Commission</span> <br />
        </h2>
      </div>
      <section className="phone:px-3 tablets:top-20 phone:pb-4 relative flex w-full flex-col items-center justify-center px-7 pt-14">
        <h1 className="phone:text-[1.4rem] mb-6 text-center text-[2rem] font-semibold text-[#4F4F4F]">
          Sorry, Something Went Wrong
        </h1>

        <p className="phone:w-full mx-auto mb-6 w-[35%] text-center">
          It looks like we couldnt find the page you have navigated to. If this
          is an error on our part please do well to report it, otherwise
          consider going back.
        </p>
        <SolidBtn classNames="w-[200px]" onClick={() => navigate("/")}>
          Take me back
        </SolidBtn>
      </section>
    </>
  );
}
