import { useRecoilState } from "recoil";
// import recoil hooks
import { useResetRecoilState } from "recoil";

// import all form states
import {
  firstNameState,
  lastNameState,
  nameState,
  emailState,
  phoneNumberState,
  residentialAddressState,
  digitalAddressState,
  dateOfIncidentState,
  descriptionState,
  claimTypeState,
  claimTypeValueState,
  filesToBeUploadedState,
  uploadedFilesState,
  policyNumberState,
  regulatedEntityIDState,
  entityOfConcernState,
  caseTypeState,
  complaintTypeState as individualComplaint,
  ghanaCardState,
  nationalIdentificationTypeState,
  userRegionState,
  numVehicleState,
} from "../recoil/individual";
import {
  businessAddressState,
  businessNameState,
  businessPhoneNumState,
  businessEmailState,
  contactPersonNameState,
  contactPersonPhoneState,
  corporateCaseTypeState,
  claimTypeState as corporateClaim,
  claimTypeStateValue,
  dateOfIncidentState as corporateDateOfIncident,
  descriptionState as corporateDescription,
  filesToBeUploadedState as corporateFileToBe,
  uploadedFilesState as corporateUploadedFiles,
  regulatedEntityIdState,
  entityOfConcernState as corporateEntity,
  policyNumberState as corporatePolicy,
  complaintTypeState,
  nationalIDState,
  IDTypeState,
  vehicleNumMotorState,
} from "../recoil/corporate";

export const useEmptyPetitionStates = () => {
  const resetIndividualComplaint = useResetRecoilState(individualComplaint);
  const resetFirstName = useResetRecoilState(firstNameState);
  const resetLastName = useResetRecoilState(lastNameState);
  const resetNameState = useResetRecoilState(nameState);
  const resetEmailState = useResetRecoilState(emailState);
  const resetPhoneState = useResetRecoilState(phoneNumberState);
  const restResidentialAdd = useResetRecoilState(residentialAddressState);
  const resetDigitalAdd = useResetRecoilState(digitalAddressState);
  const resetDateOfIncident = useResetRecoilState(dateOfIncidentState);
  const resetDescription = useResetRecoilState(descriptionState);
  const resetClaimType = useResetRecoilState(claimTypeState);
  const resetClaimValue = useResetRecoilState(claimTypeValueState);
  const resetEntityOfConcern = useResetRecoilState(entityOfConcernState);
  const resetEntityID = useResetRecoilState(regulatedEntityIDState);
  const resetPolicyNumber = useResetRecoilState(policyNumberState);
  const resetFilesToBeUploaded = useResetRecoilState(filesToBeUploadedState);
  const resetUploadedFiles = useResetRecoilState(uploadedFilesState);
  const resetIndividualRegion = useResetRecoilState(userRegionState);

  const resetBusinessAddress = useResetRecoilState(businessAddressState);
  const resetBusinessEmail = useResetRecoilState(businessEmailState);
  const resetBusinessName = useResetRecoilState(businessNameState);
  const resetBusinessPhone = useResetRecoilState(businessPhoneNumState);
  const resetContactPersonName = useResetRecoilState(contactPersonNameState);
  const resetContactPersonPhone = useResetRecoilState(contactPersonPhoneState);
  const resetCaseType = useResetRecoilState(caseTypeState);
  const resetCorporateCase = useResetRecoilState(corporateCaseTypeState);
  const resetCorporateClaimType = useResetRecoilState(corporateClaim);
  const resetCorporateClaimTypeValue = useResetRecoilState(claimTypeStateValue);
  const resetCorporateIncidentState = useResetRecoilState(
    corporateDateOfIncident
  );
  const resetCorporateDescription = useResetRecoilState(corporateDescription);
  const resetCorporateFileToBeUploaded = useResetRecoilState(corporateFileToBe);
  const resetCorporateUploaded = useResetRecoilState(corporateUploadedFiles);
  const resetCorporateRegEntityState = useResetRecoilState(
    regulatedEntityIdState
  );
  const resetCorporateEntity = useResetRecoilState(corporateEntity);
  const resetCorporatePolicy = useResetRecoilState(corporatePolicy);
  const resetCorporateComplaint = useResetRecoilState(complaintTypeState);
  const resetNationalID = useResetRecoilState(nationalIDState);
  const resetGhanaCard = useResetRecoilState(ghanaCardState);
  const resetNationalIdType = useResetRecoilState(
    nationalIdentificationTypeState
  );
  const resetCorporateIdType = useResetRecoilState(IDTypeState);
  const resetMotorVehicle = useResetRecoilState(vehicleNumMotorState);
  const resetIndividualVehicle = useResetRecoilState(numVehicleState);

  return {
    resetNameState,
    resetEmailState,
    resetPhoneState,
    resetDescription,
    resetDigitalAdd,
    restResidentialAdd,
    resetDateOfIncident,
    resetClaimType,
    resetClaimValue,
    resetEntityOfConcern,
    resetEntityID,
    resetFilesToBeUploaded,
    resetPolicyNumber,
    resetUploadedFiles,
    resetBusinessAddress,
    resetBusinessEmail,
    resetBusinessName,
    resetBusinessPhone,
    resetContactPersonName,
    resetContactPersonPhone,
    resetCaseType,
    resetCorporateCase,
    resetCorporateDescription,
    resetCorporateClaimType,
    resetCorporateClaimTypeValue,
    resetCorporateIncidentState,
    resetCorporateFileToBeUploaded,
    resetCorporateUploaded,
    resetCorporateRegEntityState,
    resetCorporateEntity,
    resetCorporatePolicy,
    resetCorporateComplaint,
    resetFirstName,
    resetLastName,
    resetIndividualComplaint,
    resetNationalID,
    resetGhanaCard,
    resetNationalIdType,
    resetCorporateIdType,
    resetIndividualRegion,
    resetMotorVehicle,
    resetIndividualVehicle,
  };
};
