/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  claimantOrPetitionerTypeState,
  petitionEmailState,
  petitionFamilyMemberTypeState,
  petitionFamilyOtherState,
  petitionFullnameState,
  petitionTypeState,
  petitionerIdState,
  petitionerPhoneState,
} from "../recoil/onboarding";
import { useEmptyPetitionStates } from "../utils/emptyFormStates";
import { useNavigate, Link } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import packageJson from "../../package.json";

export default function Onboarding() {
  const navigate = useNavigate();
  const [claimantSolicitorState, setClaimantSolicitorState] = useRecoilState(
    claimantOrPetitionerTypeState
  );

  const resetPetitionerFullName = useResetRecoilState(petitionFullnameState);
  const resetPetitionerId = useResetRecoilState(petitionerIdState);
  const resetPetitionerEmail = useResetRecoilState(petitionEmailState);
  const resetPetitionerPhone = useResetRecoilState(petitionerPhoneState);
  const resetPetitionerType = useResetRecoilState(petitionTypeState);
  const resetPetitionerFamilyType = useResetRecoilState(
    petitionFamilyMemberTypeState
  );
  const resetPetitionerFamilyOther = useResetRecoilState(
    petitionFamilyOtherState
  );

  // import reset states from custom hook
  const {
    resetBusinessAddress,
    resetBusinessEmail,
    resetBusinessName,
    resetBusinessPhone,
    resetCorporateCase,
    resetCorporateClaimType,
    resetCorporateClaimTypeValue,
    resetContactPersonName,
    resetContactPersonPhone,
    resetCorporateDescription,
    resetCorporateEntity,
    resetCorporateFileToBeUploaded,
    resetCorporateIncidentState,
    resetCorporateUploaded,
    resetCorporateRegEntityState,
    resetCorporatePolicy,
    resetCorporateComplaint,
    resetNationalID,
    resetEmailState,
    resetPhoneState,
    resetDescription,
    resetDigitalAdd,
    restResidentialAdd,
    resetDateOfIncident,
    resetClaimType,
    resetClaimValue,
    resetEntityOfConcern,
    resetEntityID,
    resetFilesToBeUploaded,
    resetPolicyNumber,
    resetUploadedFiles,
    resetLastName,
    resetCaseType,
    resetFirstName,
    resetIndividualComplaint,
    resetGhanaCard,
    resetNationalIdType,
    resetIndividualRegion,
  } = useEmptyPetitionStates();

  function onCancel() {
    resetCorporateCase();
    resetCorporateClaimType();
    resetCorporateClaimTypeValue();
    resetCorporateDescription();
    resetCorporateUploaded();
    resetCorporateEntity();
    resetCorporateRegEntityState();
    resetCorporateIncidentState();
    resetCorporateFileToBeUploaded();
    resetCorporatePolicy();
    resetCorporateComplaint();
    resetNationalID();
    resetEmailState();
    resetPhoneState();
    resetDescription();
    resetDigitalAdd();
    restResidentialAdd();
    resetDateOfIncident();
    resetClaimType();
    resetClaimValue();
    resetEntityOfConcern();
    resetEntityID();
    resetFilesToBeUploaded();
    resetPolicyNumber();
    resetUploadedFiles();
    resetBusinessAddress();
    resetBusinessEmail();
    resetBusinessName();
    resetBusinessPhone();
    resetContactPersonName();
    resetContactPersonPhone();
    resetLastName();
    resetCaseType();
    resetFirstName();
    resetIndividualComplaint();
    resetGhanaCard();
    resetNationalIdType();
    resetIndividualRegion();
    resetPetitionerEmail();
    resetPetitionerFamilyOther();
    resetPetitionerFamilyType();
    resetPetitionerId();
    resetPetitionerPhone();
    resetPetitionerType();
    resetPetitionerFullName();

    navigate("/");
  }
  return (
    <section className="relative mx-auto grid min-h-screen max-w-[125rem] grid-cols-2 p-2 font-heading">
      <div className="landing-bg relative col-span-2 mx-auto h-full w-full items-stretch rounded lg:col-span-1">
        <div className="h-full">
          <div className="mx-auto flex h-full max-w-3xl flex-col items-stretch py-6 px-8 text-white sm:py-10 sm:px-24 lg:justify-between">
            <>
              <Link to={"/"}>
                <div className="flex items-center">
                  <img
                    src="/assets/images/logo.png"
                    alt="Landing"
                    className="h-full w-10 rounded object-cover"
                  />
                  <h2 className="ml-2 text-xs font-bold uppercase leading-4">
                    <span>National</span> <br />
                    <span>Insurance</span> <br />
                    <span>Commission</span> <br />
                  </h2>
                </div>
              </Link>
            </>
            <div className="my-16 md:w-10/12 lg:pb-10">
              <h2 className="text-2xl font-semibold lg:text-5xl lg:leading-[70px]">
                Protecting the interests of policy holders{" "}
              </h2>
            </div>
            <div className="mt-10 mb-12 md:w-10/12 lg:hidden lg:pb-10">
              <div className="h-full text-center">
                <h3 className="mb-auto font-semibold tracking-wider text-white lg:text-2xl lg:font-bold lg:text-gray-primary">
                  Welcome to the NIC Complaints Portal
                </h3>
                <p className="py-9 font-medium text-white lg:py-14 lg:text-gray-primary">
                  Are you a victim making a direct claim or a petitioner making
                  a claim on behalf of a victim?
                </p>
                <div className="mx-auto flex max-w-3xl flex-col items-center">
                  <button
                    className="mb-6 w-full rounded-[5px] bg-white py-3 px-8  text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:bg-purple-500 lg:px-24 lg:text-white"
                    onClick={() => {
                      navigate("/landing");
                      setClaimantSolicitorState("claimant");
                    }}
                  >
                    Claimant / Victim
                  </button>

                  <button
                    className="mb-6 w-full rounded-[5px] bg-white py-3 px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:bg-purple-500 lg:px-24 lg:text-white"
                    onClick={() => {
                      navigate("/landing");
                      setClaimantSolicitorState("petitioner");
                    }}
                  >
                    Petitioner / Solicitor
                  </button>
                  <button
                    className="mb-6 w-full rounded-[5px] bg-white py-3 px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:bg-purple-primary lg:px-24 lg:text-white"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-10 mx-auto hidden flex-col px-7 lg:relative lg:flex">
        <div className="col-span-1 mx-auto grid h-full place-content-center justify-between">
          <div className="h-full text-center">
            <h3 className="mb-auto font-semibold tracking-wider text-white lg:text-2xl lg:font-bold lg:text-gray-primary">
              Welcome to the NIC Complaints Portal
            </h3>
            <p className="py-9 font-medium text-white lg:py-14 lg:text-gray-primary">
              Are you a victim making a direct claim or a petitioner making a
              claim on behalf of a victim?
            </p>
            <div className="mx-auto flex max-w-3xl flex-col items-center">
              <button
                className="mb-6 w-full rounded-[5px] bg-purple-primary py-3  px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:px-24 lg:text-white"
                onClick={() => {
                  navigate("/landing");
                  setClaimantSolicitorState("claimant");
                }}
              >
                Claimant / Victim
              </button>

              <button
                className="mb-6 w-full rounded-[5px] bg-purple-primary py-3 px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:px-24 lg:text-white"
                onClick={() => {
                  navigate("/landing");
                  setClaimantSolicitorState("petitioner");
                }}
              >
                Petitioner / Solicitor
              </button>
              <button
                className="mb-6 w-full rounded-[5px] bg-white py-3 px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:bg-purple-primary lg:px-24 lg:text-white"
                onClick={() => onCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <p className="mx-auto mt-auto hidden items-center text-[#959595] lg:flex">
          Powered by{" "}
          <span className="ml-1 font-semibold">Insurerity Digital</span>
        </p>
        {/* <p className="text-center">Version {packageJson?.version}</p> */}

      </div>
    </section>
  );
}
