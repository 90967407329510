import { atom } from "recoil";

export const statusTicketNumberState = atom<string>({
  key: "statusTicketNumber",
  default: "",
});

export const ticketStatusState = atom<string>({
  key: "nameOfStatus",
  default: "",
});

export const nameOfStatusHolder = atom<string>({
  key: "ticketHolderName",
  default: "",
});

export const entityResponsible = atom<string>({
  key: "responsibleEntity",
  default: "",
});
