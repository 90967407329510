import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Modal from "../../Modal";
import { ThreeDots } from "react-loader-spinner";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  selectedRegionState,
  stepState,
  vehicleNumMotorState,
} from "../../../recoil/corporate";
import { useEmptyPetitionStates } from "../../../utils/emptyFormStates";
import {
  claimTypeState,
  claimTypeStateValue,
  corporateCaseTypeState,
  dateOfIncidentState,
  descriptionState,
  businessAddressState,
  businessEmailState,
  entityOfConcernState,
  businessNameState,
  businessPhoneNumState,
  policyNumberState,
  regulatedEntityIdState,
  ticketIdState,
  uploadedFilesState,
  contactPersonNameState,
  contactPersonPhoneState,
  IDTypeState,
  nationalIDState,
  corporateRegionState,
  otherCorporateClaimNatureState,
} from "../../../recoil/corporate";
import { complaintTypeState } from "../../../recoil/individual";
import { OutlineBtn, SolidBtn } from "../../ui/buttons";
import { PreviewInfoDisplay } from "../../ui/others/PreviewInfoDisplay";
import { ADD_TICKET } from "../../../graphql/mutations";
import { toast } from "react-toastify";
import {
  petitionEmailState,
  petitionFamilyMemberTypeState,
  petitionFamilyOtherState,
  petitionFullnameState,
  petitionTypeState,
  petitionerIdState,
  petitionerPhoneState,
  claimantOrPetitionerTypeState,
  petitionerIdentifierTypeState,
} from "../../../recoil/onboarding";

export default function StepFour() {
  const {
    resetBusinessAddress,
    resetBusinessEmail,
    resetBusinessName,
    resetBusinessPhone,
    resetCorporateCase,
    resetCorporateClaimType,
    resetCorporateClaimTypeValue,
    resetContactPersonName,
    resetContactPersonPhone,
    resetCorporateDescription,
    resetCorporateEntity,
    resetCorporateFileToBeUploaded,
    resetCorporateIncidentState,
    resetCorporateUploaded,
    resetCorporateRegEntityState,
    resetCorporatePolicy,
    resetNationalID,
    resetCorporateIdType,
    resetMotorVehicle,
  } = useEmptyPetitionStates();

  interface IIdentifiers {
    documentId: string;
  }
  const [identifiers, setIdentifiers] = useState<IIdentifiers[]>([]);
  // create new modifiable state
  const [ticketIdentifier, setticketIdentifier] = useRecoilState(ticketIdState);
  const [step, setStep] = useRecoilState(stepState);

  // extract state values
  const claimType = useRecoilValue(claimTypeState);
  const claimTypeValue = useRecoilValue(claimTypeStateValue);
  const otherNatureOfClaimValue = useRecoilValue(
    otherCorporateClaimNatureState
  );
  const caseType = useRecoilValue(corporateCaseTypeState);
  const vehicle_number = useRecoilValue(vehicleNumMotorState);
  const petitionerIDType = useRecoilValue(petitionerIdentifierTypeState);
  const dateOfIncident = useRecoilValue(dateOfIncidentState);
  const description = useRecoilValue(descriptionState);
  const email = useRecoilValue(businessEmailState);
  const entityOfConcern = useRecoilValue(entityOfConcernState);
  const businessName = useRecoilValue(businessNameState);
  const phoneNumber = useRecoilValue(businessPhoneNumState);
  const policyNumber = useRecoilValue(policyNumberState);
  const businessAddress = useRecoilValue(businessAddressState);
  const complaint = useRecoilValue(complaintTypeState);
  const regulatedEntityId = useRecoilValue(regulatedEntityIdState);
  const uploadedFiles = useRecoilValue(uploadedFilesState);
  const contactPerson = useRecoilValue(contactPersonNameState);
  const contactPersonNumber = useRecoilValue(contactPersonPhoneState);
  const ghanaCard = useRecoilValue(nationalIDState);
  const nationalIdType = useRecoilValue(IDTypeState);
  const chosenRegion = useRecoilValue(selectedRegionState);
  const region = useRecoilValue(corporateRegionState);

  const petitionerEmail = useRecoilValue(petitionEmailState);
  const petitionerType = useRecoilValue(petitionTypeState);
  const petitionerId = useRecoilValue(petitionerIdState);
  const petitionerName = useRecoilValue(petitionFullnameState);
  const petitionerPhone = useRecoilValue(petitionerPhoneState);
  const petitionerFamilyMember = useRecoilValue(petitionFamilyMemberTypeState);
  const petitionerFamilyOther = useRecoilValue(petitionFamilyOtherState);

  const [openModal, setOpenModal] = useState(false);

  const claimantOrPetitioner = useRecoilValue(claimantOrPetitionerTypeState);

  const mainClaimTypeValue =
    claimTypeValue !== "Other" ? claimTypeValue : otherNatureOfClaimValue;

  const verticalLine = () => {
    return <div className="h-[22px] w-[3px] bg-purple-500"></div>;
  };

  const returnPetitionerType =
    petitionerType !== "family"
      ? petitionerType
      : `${petitionerType}(${
          petitionerFamilyMember !== "other"
            ? petitionerFamilyMember
            : petitionerFamilyOther
        })`;

  useEffect(() => {
    if (uploadedFiles.length === 1) {
      setIdentifiers((prevState) => [
        {
          documentId: uploadedFiles[0].id,
        },
        ...prevState,
      ]);
    }

    if (uploadedFiles.length > 1) {
      let docs: IIdentifiers[] = [];
      for (let i = 0; i < uploadedFiles.length; i++) {
        docs.push({ documentId: uploadedFiles[i].id });
      }
      setIdentifiers((prevState) => [...docs, ...prevState]);
    }
  }, []);

  const [addTicket, { data, loading, error }] = useMutation(ADD_TICKET, {
    variables: {
      object: {
        sourceChannel: "CLIENT",
        officeId: region,
        NationalId: ghanaCard,
        vehicle_number,
        IdType: nationalIdType,
        pertitioner_name: petitionerName,
        petitioner_id_type: petitionerIDType,
        petitioner_phone_number: petitionerPhone,
        petitioner_email: petitionerEmail,
        petitioner_id_card: petitionerId,
        petitioner_type: returnPetitionerType,
        name: businessName,
        contactNumber: phoneNumber,
        dateOfIncidence: dateOfIncident ? dateOfIncident : undefined,
        policyNumber,
        residentialAddress: businessAddress,
        email,
        complainantType: "CORPORATE",
        ticketType: complaint,
        claimType,
        claimTypeValue: mainClaimTypeValue,
        description,
        regulatedEntityId:
          complaint === "PETITION" ? regulatedEntityId : undefined,
        ComplaintDocuments: {
          data: uploadedFiles.length === 0 ? [] : identifiers,
        },
      },
    },
    onCompleted(data) {
      setticketIdentifier(data.insert_nic_ccms_Complaint_one.id);
      setStep(5);

      // clear form states
      resetBusinessAddress();
      resetBusinessEmail();
      resetBusinessName();
      resetBusinessPhone();
      resetContactPersonName();
      resetContactPersonPhone();
      resetCorporateCase();
      resetCorporateClaimType();
      resetCorporateClaimTypeValue();
      resetCorporateDescription();
      resetCorporateUploaded();
      resetCorporateEntity();
      resetCorporateRegEntityState();
      resetCorporateIncidentState();
      resetCorporateFileToBeUploaded();
      resetCorporatePolicy();
      resetNationalID();
      resetCorporateIdType();
      resetMotorVehicle();
    },
    onError(error) {
      // console.log("error creating corp complaint", error);
      toast.error("Could not create complaint at this time, try again later");
    },
  });

  function onCloseModal() {
    setOpenModal(false);
  }

  function onProceedToSubmitComplaint() {
    // submit complaint here
    setOpenModal(false);

    addTicket();
  }
  function submitComplaint() {
    setOpenModal(true);
  }

  return (
    <div className="flex w-full flex-col px-6">
      <h2 className="text-center font-heading text-2xl font-semibold text-gray-primary">
        Preview
      </h2>
      <p className="my-6 text-center font-heading text-gray-primary">
        Please make sure all details are correct before submitting
      </p>
      <div className="mb-5 flex flex-col items-center justify-between md:flex-row">
        <div className="w-full md:w-1/3">
          <OutlineBtn onClick={() => setStep(3)}>Previous</OutlineBtn>
        </div>

        <div className="mt-4 w-full md:mt-0 md:w-auto">
          <SolidBtn onClick={() => submitComplaint()}>
            {loading ? (
              <div className="flex items-center justify-center">
                <ThreeDots height={25} width={40} color="white" radius={3} />
              </div>
            ) : (
              <span>Submit Complaint</span>
            )}
          </SolidBtn>
        </div>
      </div>
      {/* information display */}
      <div className="max-h-[35rem] overflow-y-scroll rounded-[5px] bg-[rgba(0,0,0,0.02)] p-[40px]">
        {/* Personal Info Display */}
        <div className="flex flex-col gap-y-[40px]">
          <div className="flex items-center gap-x-[10px]">
            {verticalLine()}
            <h3 className="font-alt text-xl font-bold text-purple-primary">
              Business Information
            </h3>
          </div>
          {/* main data */}
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay
              headerInfo="Business Name"
              subInfo={businessName}
            />
            <PreviewInfoDisplay
              headerInfo="Business Address"
              subInfo={businessAddress}
            />
          </div>
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay headerInfo="Business Email" subInfo={email} />
            <PreviewInfoDisplay
              headerInfo="Business Phone"
              subInfo={phoneNumber}
            />
          </div>
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay
              headerInfo="Contact Person"
              subInfo={contactPerson}
            />
            <PreviewInfoDisplay
              headerInfo="Contact Person Phone"
              subInfo={contactPersonNumber}
            />
          </div>
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay headerInfo="Id Type" subInfo={nationalIdType} />
            <PreviewInfoDisplay
              headerInfo="Ghana Card Number"
              subInfo={ghanaCard}
            />
          </div>
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay headerInfo="Region" subInfo={chosenRegion} />
          </div>
        </div>
        {/* Ticket Category */}
        <div className="mt-[40px] flex flex-col gap-y-[40px]">
          <div className="flex gap-x-[10px]">
            {verticalLine()}
            <h3 className="font-alt text-xl font-bold text-purple-primary">
              Ticket Category
            </h3>
          </div>

          {/* main data */}
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay headerInfo="Ticket Type" subInfo={complaint} />
            <PreviewInfoDisplay
              headerInfo="Date of Incident"
              subInfo={dateOfIncident}
            />
          </div>
        </div>

        {/* Ticket Details */}
        <div className="mt-[40px] flex flex-col gap-y-[40px]">
          <div className="flex gap-x-[10px]">
            {verticalLine()}
            <h3 className="font-alt text-xl font-bold text-purple-primary">
              Ticket Details
            </h3>
          </div>

          {/* main data */}
          <div className="max-w-[640px]">
            <PreviewInfoDisplay
              headerInfo="Description"
              subInfo={description}
            />
          </div>
          {complaint === "PETITION" ? (
            <>
              <div className="flex justify-between [@media(max-width:425px)]:flex-col">
                <PreviewInfoDisplay
                  headerInfo="Entity of Concern"
                  subInfo={entityOfConcern}
                />
                <PreviewInfoDisplay
                  headerInfo="Complaint Type"
                  subInfo={complaint}
                />
              </div>
              <div className="flex justify-between [@media(max-width:425px)]:flex-col">
                <PreviewInfoDisplay
                  headerInfo="Policy Number"
                  subInfo={policyNumber}
                />
                <PreviewInfoDisplay
                  headerInfo="Claim Type"
                  subInfo={claimType}
                />
                <PreviewInfoDisplay
                  headerInfo="Nature of Claim"
                  subInfo={mainClaimTypeValue}
                />
              </div>
            </>
          ) : (
            <PreviewInfoDisplay headerInfo="Case Type" subInfo={caseType} />
          )}
        </div>

        {/* Petioner Information */}

        {claimantOrPetitioner !== "claimant" && (
          <div className="mt-[40px] flex flex-col gap-y-[40px]">
            <div className="flex gap-x-[10px]">
              {verticalLine()}
              <h3 className="font-alt text-xl font-bold text-purple-primary">
                Petitioner Information
              </h3>
            </div>

            {/* main data */}

            <div className="flex justify-between [@media(max-width:425px)]:flex-col">
              <PreviewInfoDisplay
                headerInfo="Petitioner Type"
                subInfo={petitionerType}
              />
              <PreviewInfoDisplay
                headerInfo="Petitioner Name"
                subInfo={petitionerName}
              />
            </div>
            <div className="flex justify-between [@media(max-width:425px)]:flex-col">
              <PreviewInfoDisplay
                headerInfo="Petitioner ID Type"
                subInfo={petitionerIDType}
              />
              <PreviewInfoDisplay
                headerInfo="Petitioner ID"
                subInfo={petitionerId}
              />
              <PreviewInfoDisplay
                headerInfo="Petitioner Email"
                subInfo={petitionerEmail}
              />
            </div>
            <div className="flex justify-between [@media(max-width:425px)]:flex-col">
              <PreviewInfoDisplay
                headerInfo="Petitioner Family Type"
                subInfo={petitionerFamilyMember}
              />
              <PreviewInfoDisplay
                headerInfo="Petitioner Phone"
                subInfo={petitionerPhone}
              />
            </div>
            <div className="flex justify-between [@media(max-width:425px)]:flex-col">
              <PreviewInfoDisplay
                headerInfo="Petitioner Family Other"
                subInfo={petitionerFamilyOther}
              />
            </div>
          </div>
        )}

        {/* Documents */}
        <div className="mt-[40px] flex flex-col gap-y-[40px]">
          <div className="flex gap-x-[10px]">
            {verticalLine()}
            <h3 className="font-alt text-xl font-bold text-purple-primary">
              Documents
            </h3>
          </div>

          {/*  */}
          <div className="flex flex-col items-start space-y-2 [@media(max-width:425px)]:flex-col">
            {uploadedFiles.map((f, i) => (
              <div key={i}>
                <PreviewInfoDisplay
                  headerInfo={`Document ${i + 1}`}
                  subInfo={f.fileName}
                  flexProp="col"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={onCloseModal}
        onProceed={onProceedToSubmitComplaint}
      >
        {/* <h2>Hello, do you certify this shit?</h2> */}
        <p>
          I certify that my answers are true and accurate to the best of my
          knowledge{" "}
        </p>
      </Modal>
    </div>
  );
}
