import React, { useState } from "react";
import { OutlineBtn, SolidBtn } from "../../ui/buttons";

import { useRecoilState } from "recoil";

import { stepState, descriptionState } from "../../../recoil/individual";

export default function StepTwo() {
  const [step, setStep] = useRecoilState(stepState);
  const [description, setDescription] = useRecoilState(descriptionState);
  const [error, setError] = useState(false);

  const handleNext = () => {
    if (description.trim().length === 0) {
      setError(true);
    } else {
      setStep(3);
    }
  };

  return (
    <section>
      <section>
        <div className="">
          <h2 className="text-center font-heading text-2xl font-semibold text-gray-primary">
            Ticket Details
          </h2>
          <p className="my-6 text-center font-heading text-gray-primary">
            Enter details in this form to submit a complaint/petition
          </p>
          <div className="mt-10 w-full px-6">
            <div className="flex flex-col gap-y-[12px]">
              <label htmlFor="email">
                Description Of Complaint <span className="text-red-500">*</span>
              </label>
              <textarea
                placeholder="Description Of Complaint"
                className="min-h-[156px] w-full appearance-none rounded border border-gray-200 bg-gray-200 py-2 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  border: error && description === "" ? "1px solid red" : "",
                }}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="mt-[30%] mb-7 flex items-center justify-between px-6">
          <div className="w-2/5 md:w-1/3">
            <OutlineBtn onClick={() => setStep(step - 1)}>Previous</OutlineBtn>
          </div>
          <div className="w-2/5 md:w-1/3">
            <SolidBtn onClick={handleNext}>Next</SolidBtn>
          </div>
        </div>
      </section>
    </section>
  );
}
