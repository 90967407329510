import { atom } from "recoil";

export const petitionEmailState = atom<string>({
  key: "pet",
  default: "",
});
export const petitionFullnameState = atom<string>({
  key: "namer",
  default: "",
});
export const petitionerPhoneState = atom<string>({
  key: "phoner",
  default: "",
});
export const petitionTypeState = atom<string>({
  key: "solicitorType",
  default: "",
});
export const petitionFamilyMemberTypeState = atom<string>({
  key: "familyType",
  default: "",
});
export const petitionerIdState = atom<string>({
  key: "detailsOfCard",
  default: "",
});
export const petitionFamilyOtherState = atom<string>({
  key: "famOtherType",
  default: "",
});

export const claimantOrPetitionerTypeState = atom<string>({
  key: "whosMakingClaim",
  default: "",
});

export const personOrCorporationState = atom<string>({
  key: "oneOrCorp",
  default: "",
});
export const petitionerIdentifierTypeState = atom<string>({
  key: "whatIsYourCard",
  default: "",
});
