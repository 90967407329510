export enum INPUTTYPES {
  text = "text",
  email = "email",
  number = "number",
  password = "password",
  radio = "radio",
  date = "date",
  file = "file",
  checkbox = "checkbox",
}

export enum IDTypes {
  voters = "VOTER",
  ghanaCard = "GHANA-CARD",
}


export const allowedFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];
