import { ChevronDown, ChevronUp } from "lucide-react";
import HelpModalWrapper from "../HelpModalWrapper";
import { useState } from "react";
import { Link } from "react-router-dom";

interface IModal {
  openModal: boolean;
  onClose: () => void;
  position?: "center-top" | "center-middle" | "top-right" | "top-left";
}

const helpTopics = [
  {
    title: "Filing a Normal Petition",
    description:
      "If you've experienced a covered event, such as property damage or theft, you can file a normal petition. This section provides step-by-step guidance on how to submit all necessary documentation.",
  },
  {
    title: "Motor Compensation Fund Claims",
    description:
      "The Motor Compensation Fund is designed to assist victims of road accidents caused by uninsured or untraced drivers. This section outlines the eligibility criteria and the steps to file a claim.",
  },
  {
    title: "Document Submission Guidelines",
    description:
      "Providing accurate documentation is crucial for a successful claim. This section lists the necessary paperwork and explains the accepted formats for each.",
  },
  {
    title: "Claim Status and Updates",
    description:
      "Stay informed about the progress of your claim. Learn how to access and interpret the status updates provided by our claims department.",
  },
  {
    title: "FAQs and Common Concerns",
    description:
      "Many claimants have similar questions. This section addresses common concerns, helping you navigate the claims process more smoothly.",
  },
  {
    title: "Contacting Support",
    description:
      "If you need direct help or have specific questions, our support team is here to assist you. Find out how to reach us via phone or email.",
  },
  {
    title: "Insurance Terms and Glossary",
    description:
      "Sometimes insurance jargon can be confusing. This section provides definitions and explanations for common terms used in insurance policies.",
  },
];

export default function HelpGuideModal({
  onClose,
  position,
  openModal,
}: IModal) {
  const [idx, setIdx] = useState<null | number>(null);
  const [data, setData] = useState(helpTopics);

  const setSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase();

    if (searchTerm !== "") {
      setData((prevData) =>
        prevData.filter(
          (item) =>
            item.title.toLowerCase().includes(searchTerm) ||
            item.description.toLowerCase().includes(searchTerm)
        )
      );
    } else {
      setData(helpTopics);
    }
  };

  return (
    <HelpModalWrapper open={openModal} onClose={onClose} position={position}>
      <div className="space-y-4 scroll-smooth">
        <h2 className=" text-xl font-bold">How can we assist you today?</h2>

        <div className="my-4 mb-4">
          <input
            onChange={(e) => setSearch(e)}
            type="text"
            placeholder="what are you looking for?"
            className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:border-purple-primary focus:outline-none"
          />
        </div>

        <div className="mt-10 space-y-2">
          {data.map((v, i) => (
            <div key={v.title}>
              <div
                className="mb-2 flex cursor-pointer items-center justify-between transition"
                onClick={() => {
                  if (idx === i) {
                    setIdx(null);
                    return;
                  }

                  setIdx(i);
                }}
              >
                <h4 className="text-lg font-semibold">{v.title}</h4>
                {idx === i ? (
                  <ChevronUp size={14} />
                ) : (
                  <ChevronDown size={14} />
                )}
              </div>
              <p
                className={`${
                  idx === i ? "block transition-all duration-300" : "hidden"
                } text-sm text-gray-500`}
              >
                {v.description}
              </p>
            </div>
          ))}
        </div>

        <div>
          <span className="pr-2 text-sm text-gray-500">
            Can't find what you're looking for?
          </span>
          <Link
            to="/help"
            className="font-semibold text-purple-primary underline"
            target="_blank" rel="noopener noreferrer"
          >
            Visit our help page
          </Link>
        </div>
      </div>
    </HelpModalWrapper>
  );
}
