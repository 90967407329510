import Modal from "../../../components/Modal";

interface IModal {
  openModal: boolean;
  onProceed: () => void;
  onClose: () => void;
}

export default function MotorFundDocumentsRequired({
  onClose,
  onProceed,
  openModal,
}: IModal) {
  return (
    <Modal
      open={openModal}
      onClose={onClose}
      onProceed={onProceed}
      onBiggerWidth={true}
      cancelText="Cancel"
    >
      <h2 className="pt-2 pb-4 text-center text-lg font-bold">
        Please proceed only if you have these documents
      </h2>
      <div className="space-y-4">
        <div className="px-4">
          <h2 className="mb-4 text-center text-base font-semibold">
            Death Case Required Documents
          </h2>
          <ul className="m-0 flex list-disc flex-col items-start gap-y-2 p-0">
            <li>Original Police Accident report</li>
            <li>Original Death Certificate or Burial permit</li>
            <li>Original Letters of Administration (adult)</li>
            <li>Original Statutory Declaration (minor) </li>
            <li>
              Original Affidavit stating the name of the spouse and children as
              well as their ages.
            </li>
            <li>
              The Administrator(s) National ID card, preferably Ghana card.
            </li>
            <li>Two (2) passport sized pictures of the Administrator(s)</li>
            <li>
              An affidavit of correction of name if the name on the Letters of
              Administration and Statutory Declaration differs from that on the
              National Identity card. Reference should be made to the mistakes.
            </li>
            <li>
              An affidavit to correct the deceased’s name should there be a
              mistake on any of the documents provided. Reference should be made
              to the mistakes.
            </li>

            <li>
              An affidavit of instruction/ authorization if a solicitor is
              involved.
            </li>
            <li>
              Repudiation letter if there is an insurance company mentioned on
              the Police Accident report.
            </li>
          </ul>
        </div>

        <div className="px-4">
          <h2 className="mb-4 text-center text-base font-semibold">
            Injury Case Required Documents
          </h2>
          <ul className="m-0 flex list-disc flex-col items-start gap-y-2 p-0">
            <li>Original Police Accident report</li>
            <li>Original Medical report</li>
            <li>The claimant’s National ID card, preferably Ghana card</li>
            <li>
              Two (2) passport sized pictures endorsed by the Medical Doctor
            </li>
            <li>
              Pictures of the injury with the victim’s face fully showing.
            </li>
            <li>
              An affidavit of instruction/ authorization if a solicitor is
              involved.
            </li>
            <li>
              An affidavit of correction of name if the name on the Medical
              report and Police Accident report differs from that on the
              National Identity card. Reference should be made to the mistakes.{" "}
            </li>
            <li>Original Medical receipts and prescriptions (if any).</li>
            <li>
              Repudiation letter if there is an insurance company mentioned on
              the Police Accident report.
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
}
