/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate, Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  claimantOrPetitionerTypeState,
  personOrCorporationState,
} from "../recoil/onboarding";
import packageJson from "../../package.json";

export default function Landing() {
  const navigate = useNavigate();
  const claimantOrPetitioner = useRecoilValue(claimantOrPetitionerTypeState);
  const [individualOrCorp, setIndividualOrCorp] = useRecoilState(
    personOrCorporationState
  );

  function onCancel() {
    navigate("/all");
  }

  return (
    <section className="relative mx-auto grid min-h-screen max-w-[125rem] grid-cols-2 p-2 font-heading">
      <div className="landing-bg relative col-span-2 mx-auto h-full w-full items-stretch rounded lg:col-span-1">
        <div className="h-full">
          <div className="mx-auto flex h-full max-w-3xl flex-col items-stretch py-6 px-8 text-white sm:py-10 sm:px-24 lg:justify-between">
            <>
              <Link to={"/"}>
                <div className="flex items-center">
                  <img
                    src="/assets/images/logo.png"
                    alt="Landing"
                    className="h-full w-10 rounded object-cover"
                  />
                  <h2 className="ml-2 text-xs font-bold uppercase leading-4">
                    <span>National</span> <br />
                    <span>Insurance</span> <br />
                    <span>Commission</span> <br />
                  </h2>
                </div>
              </Link>
            </>
            <div className="my-16 md:w-10/12 lg:pb-10">
              <h2 className="text-2xl font-semibold lg:text-5xl lg:leading-[70px]">
                Protecting the interests of policy holders{" "}
              </h2>
            </div>
            <div className="mt-10 mb-12 md:w-10/12 lg:hidden lg:pb-10">
              <div className="h-full text-center">
                <h3 className="mb-auto font-semibold tracking-wider text-white lg:text-2xl lg:font-bold lg:text-gray-primary">
                  Welcome to the NIC Complaints Portal
                </h3>
                <p className="py-9 font-medium text-white lg:py-14 lg:text-gray-primary">
                  Is the victim an individual or a corporate entity?
                </p>
                <div className="mx-auto flex max-w-3xl flex-col items-center">
                  <button
                    className="mb-6 w-full rounded-[5px] bg-white py-3 px-8  text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:bg-purple-500 lg:px-24 lg:text-white"
                    onClick={() => {
                      setIndividualOrCorp("individual");
                      claimantOrPetitioner === "claimant"
                        ? navigate("/complaint/single")
                        : navigate("/onboarding");
                    }}
                  >
                    Individual
                  </button>

                  <button
                    className="mb-6 w-full rounded-[5px] bg-white py-3 px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:bg-purple-primary lg:px-24 lg:text-white"
                    onClick={() => {
                      setIndividualOrCorp("corporate");
                      claimantOrPetitioner === "claimant"
                        ? navigate("/complaint/corporate")
                        : navigate("/onboarding");
                    }}
                  >
                    Corporate
                  </button>
                  <button
                    className="mb-6 w-full rounded-[5px] bg-white py-3 px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:bg-purple-primary lg:px-24 lg:text-white"
                    onClick={() => onCancel()}
                  >
                    Go back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-10 mx-auto hidden flex-col px-7 lg:relative lg:flex">
        <div className="col-span-1 mx-auto grid h-full place-content-center justify-between">
          <div className="h-full text-center">
            <h3 className="mb-auto font-semibold tracking-wider text-white lg:text-2xl lg:font-bold lg:text-gray-primary">
              Welcome to the NIC Complaints Portal
            </h3>
            <p className="py-9 font-medium text-white lg:py-14 lg:text-gray-primary">
              Is the victim an individual or a corporate entity?
            </p>
            <div className="mx-auto flex max-w-3xl flex-col items-center">
              <button
                className="mb-6 w-full rounded-[5px] bg-purple-primary py-3  px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:px-24 lg:text-white"
                onClick={() => {
                  setIndividualOrCorp("individual");
                  claimantOrPetitioner === "claimant"
                    ? navigate("/complaint/single")
                    : navigate("/onboarding");
                }}
              >
                Individual
              </button>

              <button
                className="mb-6 w-full rounded-[5px] bg-purple-primary py-3 px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:px-24 lg:text-white"
                onClick={() => {
                  setIndividualOrCorp("corporate");

                  claimantOrPetitioner === "claimant"
                    ? navigate("/complaint/corporate")
                    : navigate("/onboarding");
                }}
              >
                Corporate
              </button>
              <button
                className="mb-6 w-full rounded-[5px] bg-white py-3 px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:bg-purple-primary lg:px-24 lg:text-white"
                onClick={() => onCancel()}
              >
                Go back
              </button>
            </div>
          </div>
        </div>
        <p className="mx-auto mt-auto hidden items-center text-[#959595] lg:flex">
          Powered by{" "}
          <span className="ml-1 font-semibold">Insurerity Digital</span>
        </p>
        {/* <p className="text-center">Version {packageJson?.version}</p> */}

      </div>
    </section>
  );
}
