/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRecoilValue } from 'recoil';
import { stepState } from '../../../recoil/individual';

export default function HorizontalTracker() {
	const step = useRecoilValue(stepState);

	return (
		<aside className=''>
			<div className='flex justify-center items-start'>
				{/* first item */}
				<div className='flex flex-col items-center'>
					<p className='bg-gray-400 rounded-full h-6 w-6 flex items-center justify-center'>
						<span>1</span>
					</p>
					<p className='pt-2 ml-3 text-[14px]'>Ticket Details</p>
				</div>
				{/* second item (step) */}
				<div className='h-[4px] w-11 bg-gray-300 mx-2 my-2 rounded'>
					{step === 1 && <div className='w-6 h-[4px] bg-purple-500'></div>}
					{step > 1 && <div className='w-full h-[4px] bg-purple-500'></div>}
				</div>
				{/* third item */}
				<div className='flex flex-col items-center'>
					<p className='bg-gray-400 rounded-full h-6 w-6 flex items-center justify-center'>
						<span>2</span>
					</p>
					<p className='pt-2 ml-3 text-[14px]'>Documents</p>
				</div>
				{/* fourth item (step) */}
				<div className='w-11 h-[4px] bg-gray-300 mx-2 my-2 rounded'>
					{step === 2 && <div className='w-6 h-[4px] bg-purple-500'></div>}
					{step > 2 && <div className='w-full h-[4px] bg-purple-500'></div>}
				</div>
				{/* fifth item */}
				<div className='flex flex-col items-center'>
					<p className='bg-gray-400 rounded-full h-6 w-6 flex items-center justify-center'>
						<span>3</span>
					</p>
					<p className='pt-2 ml-3 text-[14px]'>Preview</p>
				</div>
				{/* sixth item (step) */}
				<div className='w-11 h-[4px] bg-gray-300 mx-2 my-2 rounded'>
					{step === 3 && <div className='w-6 h-[4px] bg-purple-500'></div>}
					{step > 3 && <div className='w-full h-[4px] bg-purple-500'></div>}
				</div>
				{/* seventh item */}
				<div className='flex flex-col items-center'>
					<p className='bg-gray-400 rounded-full h-6 w-6 flex items-center justify-center'>
						<span>4</span>
					</p>
					<p className='pt-2 ml-3 text-[14px]'>Submit</p>
				</div>
			</div>
		</aside>
	);
}
