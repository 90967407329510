import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

import { InputComponent } from "../components/ui/inputs";
import { OutlineBtn, SolidBtn } from "../components/ui/buttons";
import { INPUTTYPES } from "../types";
import Header from "../components/ui/Header";
import {
  petitionEmailState,
  petitionFullnameState,
  petitionTypeState,
  petitionerPhoneState,
  petitionFamilyMemberTypeState,
  petitionerIdState,
  petitionFamilyOtherState,
  personOrCorporationState,
  claimantOrPetitionerTypeState,
  petitionerIdentifierTypeState,
} from "../recoil/onboarding";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isEmail } from "../utils/email";

const IDTypeList = [
  "Ghana Card",
  "Voter ID",
  "TIN",
  "Health Insurance",
  "Driver's License",
  "Passport Number",
];

export default function PetitionerOnboarding() {
  const [ghanaCardError, setGhanaCardError] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [petitionerEmail, setPetitionerEmail] =
    useRecoilState(petitionEmailState);
  const [petitionerFullname, setPetitionerFullname] = useRecoilState(
    petitionFullnameState
  );
  const [petitionerID, setPetitionerID] = useRecoilState(petitionerIdState);
  const [petitionerIDTye, setPetitionerIDType] = useRecoilState(
    petitionerIdentifierTypeState
  );
  const [petitionerType, setPetitionerType] = useRecoilState(petitionTypeState);
  const [petitionerPhoneNumber, setPetitionerPhoneNumber] =
    useRecoilState(petitionerPhoneState);
  const [petitionerFamilyMemberType, setPetitionerFamilyMemberType] =
    useRecoilState(petitionFamilyMemberTypeState);
  const [petitionerFamilyOther, setPetitionerFamilyOther] = useRecoilState(
    petitionFamilyOtherState
  );

  const resetPetitionerFullName = useResetRecoilState(petitionFullnameState);
  const resetPetitionerId = useResetRecoilState(petitionerIdState);
  const resetPetitionerIdType = useResetRecoilState(
    petitionerIdentifierTypeState
  );
  const resetPetitionerEmail = useResetRecoilState(petitionEmailState);
  const resetPetitionerPhone = useResetRecoilState(petitionerPhoneState);
  const resetPetitionerType = useResetRecoilState(petitionTypeState);
  const resetPetitionerFamilyType = useResetRecoilState(
    petitionFamilyMemberTypeState
  );
  const resetPetitionerFamilyOther = useResetRecoilState(
    petitionFamilyOtherState
  );

  // state values
  const individualOrCorp = useRecoilValue(personOrCorporationState);
  const claimantOrPetitioner = useRecoilValue(claimantOrPetitionerTypeState);

  /**
   * @description - this function handles the phone number input & validates it
   * @param e
   */
  function handlePhoneInput(e: React.ChangeEvent<HTMLInputElement>) {
    setPetitionerPhoneNumber(e.target.value);

    if (e.target.value.length > 10 || e.target.value.length < 10) {
      setPhoneErr(true);
    } else {
      setPhoneErr(false);
    }
  }

  /**
   * @description this function handles the emailInput and validates it
   * @param e
   */
  function handleEmailInput(e: React.ChangeEvent<HTMLInputElement>) {
    setPetitionerEmail(e.target.value);

    if (!isEmail(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }

  /**
   * @description function that validates ghana card input
   * @param
   */
  function handleGhanaCardChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (petitionerIDTye === "Ghana Card") {
      setPetitionerID(e.currentTarget.value);
      const splitId = e.target.value.split("-");
      if (
        splitId[0] !== "GHA" ||
        splitId[1]?.length !== 9 ||
        splitId[2]?.length !== 1
      ) {
        setGhanaCardError(true);
      } else {
        setGhanaCardError(false);
      }
    } else {
      setGhanaCardError(false);
      setPetitionerID(e.currentTarget.value);
    }
  }

  /***
   * @description function that resets all state and takes the user back to the root page
   */

  function onCancel() {
    // function to empty form states when user clicks on cancel button
    resetPetitionerEmail();
    resetPetitionerFamilyOther();
    resetPetitionerFamilyType();
    resetPetitionerFullName();
    resetPetitionerPhone();
    resetPetitionerType();
    resetPetitionerId();
    resetPetitionerIdType();

    navigate("/landing");
  }

  /**
   * @description - function that validates input before proceeding to next stage
   */

  function onProceed() {
    if (
      petitionerFullname.trim().length === 0 ||
      petitionerID.trim().length === 0 ||
      petitionerType.trim().length === 0
    ) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    individualOrCorp === "individual"
      ? navigate("/complaint/single")
      : navigate("/complaint/corporate");
  }

  return (
    <section className="mx-auto min-h-screen max-w-[100rem] px-2">
      <Header />

      <section className="mx-auto flex h-full max-w-4xl flex-col items-stretch justify-between md:px-16 xl:mr-64">
        <div className="mb-4 flex flex-col items-center justify-center">
          <h2 className="mb-2 font-bold">Petitioner / Solicitor Information</h2>
          <p className="text-center">Enter details in this form to proceed to creating a complaint</p>
        </div>

        <div>
          <div className="mt-10 flex w-auto max-w-[1100px] flex-col items-center justify-between px-6 md:flex-row">
            <div className="mx-2 flex w-full flex-col gap-y-[12px]">
              <label htmlFor="petitioner-type">
                Petitioner / Solicitor Type{" "}
                <span className="text-red-500">*</span>
              </label>
              <select
                name="petitioner-type"
                id="petitioner-type"
                className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                placeholder="Choose Petitioner Type"
                value={petitionerType}
                onChange={(e) => setPetitionerType(e.target.value)}
                style={{
                  border: error && petitionerType === "" ? "1px solid red" : "",
                }}
              >
                {/* This first option must not be deleted */}
                <option value="" disabled selected>
                  Choose Solicitor / Petitioner Type
                </option>
                <option value="individual">Individual</option>
                <option value="broker">Broker</option>

                <option value="corporate">Corporate</option>
                <option value="legal-entity">Legal Entity</option>
                <option value="family">Family Member</option>
              </select>
            </div>
            <div className="mx-2 mt-10 flex w-full flex-col gap-y-[12px] md:mt-0">
              <label htmlFor="fullname">
                {petitionerType !== "family" ? "Name" : "Full Name"}{" "}
                <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                name="fullname"
                classNames="py-5"
                placeholder={petitionerType !== "family" ? "Name" : "Full Name"}
                value={petitionerFullname}
                onChange={(e) => setPetitionerFullname(e.target.value)}
                style={{
                  border:
                    error && petitionerFullname === "" ? "1px solid red" : "",
                }}
              />
            </div>
          </div>

          <div className="mt-10 flex w-auto max-w-[1100px] flex-col items-center justify-between px-6 md:flex-row">
            <div className="mx-2 mt-10 flex w-full flex-col gap-y-[12px] md:mt-0">
              <label htmlFor="email">Email</label>
              <InputComponent
                type={INPUTTYPES.text}
                name="email"
                classNames="py-5"
                placeholder="Email Address"
                value={petitionerEmail}
                onChange={(e) => handleEmailInput(e)}
                style={{
                  border:
                    emailError && petitionerEmail !== "" && error
                      ? "1px solid red"
                      : "",
                }}
              />
            </div>

            <div className="mx-2 mt-10 flex w-full flex-col gap-y-[12px] md:mt-0">
              <label htmlFor="phone">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                name="phone"
                classNames="py-5"
                placeholder="Phone Number"
                value={petitionerPhoneNumber}
                onChange={(e) => handlePhoneInput(e)}
                style={{
                  border:
                    (error && petitionerPhoneNumber.trim().length === 0) ||
                    phoneErr
                      ? "1px solid red"
                      : "",
                }}
              />
            </div>
          </div>

          <div className="mt-10 flex w-auto max-w-[1100px] flex-col items-center justify-between px-6 md:flex-row">
            <div className="mx-2 mt-10 flex w-full flex-col gap-y-[12px] md:mt-0">
              <label htmlFor="idType">
                Type of ID<span className="text-red-500">*</span>
              </label>
              <select
                name="idType"
                id="IdType"
                value={petitionerIDTye}
                onChange={(e) => setPetitionerIDType(e.target.value)}
                className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                style={{
                  border:
                    error && petitionerIDTye.trim().length === 0
                      ? "1px solid red"
                      : "",
                }}
              >
                <option value="" disabled>
                  Select ID Type
                </option>
                {IDTypeList.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </select>
            </div>
            <div className="mx-2 mt-10 flex w-full flex-col gap-y-[12px] md:mt-0">
              <label htmlFor="id_card">
                {petitionerIDTye === "" ? "ID Type Value" : petitionerIDTye}{" "}
                <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                name="id_card"
                classNames="py-5"
                placeholder={
                  petitionerIDTye === "" ? "ID Type Value" : petitionerIDTye
                }
                value={petitionerID}
                onChange={(e) => handleGhanaCardChange(e)}
                style={{
                  border:
                    (error && petitionerID.trim().length === 0) ||
                    ghanaCardError
                      ? "1px solid red"
                      : "",
                }}
              />
            </div>
          </div>

          <div className="mt-10 flex w-auto max-w-[1100px] flex-col items-center justify-between px-6 md:flex-row">
            {/* show family member select if petitionerType === family-member */}
            {petitionerType === "family" && (
              <div className="mx-2 flex w-full flex-col gap-y-[12px]">
                <label htmlFor="family-member-type">
                  Family Member Type <span className="text-red-500">*</span>
                </label>
                <select
                  name="family-member-type"
                  id="family-member-type"
                  className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                  placeholder="Choose Petitioner Type"
                  value={petitionerFamilyMemberType}
                  onChange={(e) =>
                    setPetitionerFamilyMemberType(e.target.value)
                  }
                  style={{
                    border:
                      error && petitionerFamilyMemberType === ""
                        ? "1px solid red"
                        : "",
                  }}
                >
                  {/* This first option must not be deleted */}
                  <option value="" disabled selected>
                    Choose Type of Family Member
                  </option>
                  <option value="brother">Brother</option>
                  <option value="sister">Sister</option>
                  <option value="mother">Mother</option>
                  <option value="father">Father</option>
                  <option value="uncle">Uncle</option>
                  <option value="cousin">Cousin</option>
                  <option value="nephew">Nephew</option>
                  <option value="other">Other</option>
                </select>
              </div>
            )}
            {petitionerFamilyMemberType === "other" && (
              <InputComponent
                type={INPUTTYPES.text}
                placeholder="Specify Type of Family Member"
                value={petitionerFamilyOther}
                onChange={(e) => setPetitionerFamilyOther(e.target.value)}
                style={{
                  border:
                    error && petitionerFamilyOther === ""
                      ? "1px solid red"
                      : "",
                }}
              />
            )}
          </div>
        </div>

        <div className="mt-[4rem] mb-8 flex items-center justify-between md:mt-[10%]">
          <div className="w-2/5 md:w-1/3">
            <OutlineBtn onClick={() => onCancel()}>Cancel</OutlineBtn>
          </div>
          <div className="w-[55%] md:w-1/3">
            <SolidBtn onClick={onProceed}>Proceed</SolidBtn>
          </div>
        </div>
      </section>
    </section>
  );
}
