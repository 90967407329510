import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { INPUTTYPES } from "../../types";
import Header from "./Header";
import { InputComponent } from "./inputs";
import { FETCH_REGIONAL_OFFICES } from "../../graphql/queries";
import { OutlineBtn, SolidBtn } from "./buttons";

import {
  businessNameState,
  businessEmailState,
  businessAddressState,
  businessPhoneNumState,
  contactPersonNameState,
  contactPersonPhoneState,
  nationalIDState,
  IDTypeState,
  corporateRegionState,
  selectedRegionState,
} from "../../recoil/corporate";
import { isEmail } from "../../utils/email";
import { useQuery } from "@apollo/client";
import { claimantOrPetitionerTypeState } from "../../recoil/onboarding";

interface IOffices {
  name: string;
  id: string;
  __typename: string;
}

const IDTypeList = [
  "Ghana Card",
  "Voter ID",
  "TIN",
  "Health Insurance",
  "Driver's License",
  "Passport Number",
];

export default function CorporateInfo() {
  const [businessName, setBusinessName] = useRecoilState(businessNameState);
  const [businessEmail, setBusinessEmail] = useRecoilState(businessEmailState);
  const [businessAddress, setBusinessAddress] =
    useRecoilState(businessAddressState);
  const [businessNumber, setBusinessNumber] = useRecoilState(
    businessPhoneNumState
  );

  const [contactPersonName, setContactPersonName] = useRecoilState(
    contactPersonNameState
  );
  const [contactPersonPhoneNum, setContactPersonPhoneNum] = useRecoilState(
    contactPersonPhoneState
  );
  const [ghanaCard, setGhanaCard] = useRecoilState(nationalIDState);
  const [nationalIdType, setNationalIdType] = useRecoilState(IDTypeState);
  const [region, setRegion] = useRecoilState(corporateRegionState);
  const [selectedRegion, setSelectedRegion] =
    useRecoilState(selectedRegionState);

  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cardErr, setCardErr] = useState(false);
  const [ghanaCardError, setGhanaCardError] = useState(false);
  const [businessNumberErr, setBusinessNumberErr] = useState(false);
  const [businessNumberErr2, setBusinessNumberErr2] = useState(false);

  const claimantOrPetitioner = useRecoilValue(claimantOrPetitionerTypeState);

  const onCreateCompalint = () => {
    if (
      businessName.trim().length === 0 ||
      businessAddress.trim().length === 0 ||
      businessNumber.trim().length === 0 ||
      contactPersonName.trim().length === 0 ||
      contactPersonPhoneNum.trim().length === 0 ||
      ghanaCard.trim().length === 0 ||
      nationalIdType.trim().length === 0 ||
      region.trim().length === 0
    ) {
      setError(true);
      return;
    } else if (ghanaCardError) {
      setError(false);
      return;
    } else {
      navigate("/complaint/corporate/corporate-details");
    }
  };

  // function to empty form states when user clicks on cancel button
  function emptyFormStates() {
    if (claimantOrPetitioner === "claimant") {
      navigate("/landing");
    } else {
      navigate("/onboarding");
    }
  }

  function handleEmailInput(e: React.ChangeEvent<HTMLInputElement>) {
    setBusinessEmail(e.target.value);

    if (!isEmail(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }

  function handleIDChange(e: React.ChangeEvent<HTMLInputElement>) {
    setGhanaCard(e.currentTarget.value);

    if (nationalIdType === "Ghana Card") {
      const splitId = e.target.value.split("-");
      if (
        splitId[0] !== "GHA" ||
        splitId[1]?.length !== 9 ||
        splitId[2]?.length !== 1
      ) {
        setGhanaCardError(true);
      } else {
        setGhanaCardError(false);
      }
    } else {
      setGhanaCardError(false);
    }
  }

  function handlePhoneInput(e: React.ChangeEvent<HTMLInputElement>) {
    setBusinessNumber(e.target.value);

    if (e.target.value.length > 10 || e.target.value.length < 10) {
      setBusinessNumberErr(true);
    } else {
      setBusinessNumberErr(false);
    }
  }
  function handlePhoneInput2(e: React.ChangeEvent<HTMLInputElement>) {
    setContactPersonPhoneNum(e.target.value);

    if (e.target.value.length > 10 || e.target.value.length < 10) {
      setBusinessNumberErr2(true);
    } else {
      setBusinessNumberErr2(false);
    }
  }

  const { data: allOffices } = useQuery(FETCH_REGIONAL_OFFICES, {
    fetchPolicy: "cache-and-network",
    onError(error) {},
    onCompleted(data) {},
  });

  function getSelectedRegion(identifier: string) {
    setSelectedRegion("");
    const chosenRegion = allOffices?.Office?.find(
      (office: IOffices) => office?.id === identifier
    );
    if (chosenRegion) {
      setSelectedRegion(chosenRegion?.name);
    }
  }

  return (
    <section className="mx-auto min-h-screen max-w-[100rem] px-2">
      <Header />
      <section className="mx-auto flex h-full max-w-4xl flex-col items-stretch justify-between px-6 md:px-16">
        <div className="">
          <h2 className="font-heading text-2xl font-semibold text-gray-primary md:text-center">
            Business Information
          </h2>
          <p className="my-6 font-heading text-gray-primary md:text-center">
            Enter details in this form to submit a complaint/petition
          </p>
          <div className="mt-10 flex flex-col items-center justify-between md:flex-row">
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="first_name">
                Business Name <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                placeholder="Business Name"
                name="business_name"
                classNames="py-5 w-full"
                value={businessName}
                onChange={(e) => setBusinessName(e.currentTarget.value)}
                style={{
                  border: error && businessName === "" ? "1px solid red" : "",
                }}
              />
            </div>
            <div className="mt-10 flex w-full flex-col  gap-y-[12px] md:mx-0 md:mt-0 md:w-[47%]">
              <label htmlFor="last_name">
                Business Address <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                placeholder="Business Address"
                name="business_address"
                classNames="py-5 w-full"
                value={businessAddress}
                onChange={(e) => setBusinessAddress(e.currentTarget.value)}
                style={{
                  border:
                    error && businessAddress === "" ? "1px solid red" : "",
                }}
              />
            </div>
          </div>
          <div className="mt-10 flex flex-col items-center justify-between md:flex-row">
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="email">
                Business Phone Number <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.number}
                placeholder="Enter your phone number"
                name="phone"
                classNames="py-5"
                value={businessNumber}
                onChange={(e) => handlePhoneInput(e)}
                style={{
                  border:
                    (error && businessNumber.trim().length === 0) ||
                    businessNumberErr
                      ? "1px solid red"
                      : "",
                }}
              />
            </div>
            <div className="mt-10 flex w-full flex-col  gap-y-[12px] md:mx-0 md:mt-0 md:w-[47%]">
              <label htmlFor="phone_number">Business Email</label>
              <InputComponent
                placeholder="Business Email"
                value={businessEmail}
                classNames="py-5 w-full"
                onChange={(e) => handleEmailInput(e)}
                style={{
                  border:
                    businessEmail !== "" && emailError ? "1px solid red" : "",
                }}
                type={INPUTTYPES.email}
              />
            </div>
          </div>

          <div className="mt-10 flex flex-col items-center justify-between md:flex-row">
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="residential_address">
                Name of Contact Person <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                placeholder="Name of Contact Person"
                name="contact_person"
                classNames="py-5"
                value={contactPersonName}
                onChange={(e) => setContactPersonName(e.currentTarget.value)}
                style={{
                  border:
                    error && contactPersonName === "" ? "1px solid red" : "",
                }}
              />
            </div>
            <div className="mt-10 flex w-full flex-col  gap-y-[12px] md:mx-0 md:mt-0 md:w-[47%]">
              <label htmlFor="digital_address">
                Contact Person's Number <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.number}
                placeholder="Enter your phone number"
                name="phone"
                classNames="py-5"
                value={contactPersonPhoneNum}
                onChange={(e) => handlePhoneInput2(e)}
                style={{
                  border:
                    (error && contactPersonPhoneNum.trim().length === 0) ||
                    businessNumberErr2
                      ? "1px solid red"
                      : "",
                }}
              />
            </div>
          </div>
          <div className="mt-10 flex flex-col md:space-y-0 space-y-8 items-center justify-between md:flex-row">
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="residential_address">
                Region<span className="text-red-500">*</span>
              </label>
              <select
                name="region"
                id="region"
                value={region}
                onChange={(e) => {
                  setRegion(e.target.value);
                  getSelectedRegion(e.target.value);
                }}
                className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                style={{
                  border:
                    error && region.trim().length === 0 ? "1px solid red" : "",
                }}
              >
                <option value="" disabled>
                  Select Your Region
                </option>
                {allOffices?.Office.filter(
                  (v: IOffices) => v.name !== "HEAD OFFICE"
                ).map((region: IOffices) => (
                  <option key={region?.id} value={region?.id}>
                    {region?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="residential_address">
                Type of ID<span className="text-red-500">*</span>
              </label>
              <select
                name="idType"
                id="IdType"
                value={nationalIdType}
                onChange={(e) => setNationalIdType(e.target.value)}
                className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                style={{
                  border:
                    error && nationalIdType.trim().length === 0
                      ? "1px solid red"
                      : "",
                }}
              >
                <option value="" disabled>
                  Select ID Type
                </option>
                {IDTypeList.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-10 flex flex-col items-center justify-between md:flex-row">
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="idVal">
                {nationalIdType === "" ? "ID Type Value" : nationalIdType}{" "}
                <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                placeholder={nationalIdType ? "ID Type Value" : nationalIdType}
                name="idVal"
                classNames="py-5"
                value={ghanaCard}
                onChange={(e) => handleIDChange(e)}
                style={{
                  border:
                    (error && ghanaCard.trim().length === 0) ||
                    cardErr ||
                    ghanaCardError
                      ? "1px solid red"
                      : "",
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-[6rem] mb-8 flex items-center justify-between md:mb-0 md:mt-[20%]">
          <div className="w-2/5 md:w-1/3">
            <OutlineBtn onClick={emptyFormStates}>Cancel</OutlineBtn>
          </div>
          <div className="w-[55%] md:w-1/3">
            <SolidBtn onClick={onCreateCompalint}>Create Complaint</SolidBtn>
          </div>
        </div>
      </section>
    </section>
  );
}
