import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import Modal from "../../Modal";
import { ThreeDots } from "react-loader-spinner";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEmptyPetitionStates } from "../../../utils/emptyFormStates";
import { claimantOrPetitionerTypeState } from "../../../recoil/onboarding";

import { useMutation } from "@apollo/client";
import {
  stepState,
  claimTypeState,
  claimTypeValueState,
  caseTypeState,
  dateOfIncidentState,
  descriptionState,
  digitalAddressState,
  ghanaCardState,
  emailState,
  entityOfConcernState,
  firstNameState,
  lastNameState,
  phoneNumberState,
  policyNumberState,
  residentialAddressState,
  complaintTypeState,
  regulatedEntityIDState,
  ticketId,
  uploadedFilesState,
  nationalIdentificationTypeState,
  userRegionState,
  chosenRegionState,
  otherNatureOfClaimState,
  numVehicleState,
} from "../../../recoil/individual";
import { OutlineBtn, SolidBtn } from "../../ui/buttons";
import { PreviewInfoDisplay } from "../../ui/others/PreviewInfoDisplay";
import { ADD_TICKET } from "../../../graphql/mutations";
import {
  petitionEmailState,
  petitionFamilyMemberTypeState,
  petitionFamilyOtherState,
  petitionFullnameState,
  petitionTypeState,
  petitionerIdState,
  petitionerPhoneState,
  petitionerIdentifierTypeState,
} from "../../../recoil/onboarding";

export default function StepFour() {
  const {
    resetNameState,
    resetEmailState,
    resetPhoneState,
    resetDescription,
    resetDigitalAdd,
    restResidentialAdd,
    resetDateOfIncident,
    resetClaimType,
    resetClaimValue,
    resetEntityOfConcern,
    resetEntityID,
    resetFilesToBeUploaded,
    resetPolicyNumber,
    resetUploadedFiles,
    resetContactPersonName,
    resetContactPersonPhone,
    resetCaseType,
    resetGhanaCard,
    resetIndividualRegion,
    resetIndividualVehicle,
  } = useEmptyPetitionStates();

  interface IIdentifiers {
    documentId: string;
  }
  const [identifiers, setIdentifiers] = useState<IIdentifiers[]>([]);

  // create new modifiable state
  const [ticketIdentifier, setticketIdentifier] = useRecoilState(ticketId);
  const [step, setStep] = useRecoilState(stepState);

  // extract state values
  const claimType = useRecoilValue(claimTypeState);
  const vehicle_number = useRecoilValue(numVehicleState);
  const otherNatureOfClaimValue = useRecoilValue(otherNatureOfClaimState);
  const claimValue = useRecoilValue(claimTypeValueState);
  const caseType = useRecoilValue(caseTypeState);
  const dateOfIncident = useRecoilValue(dateOfIncidentState);
  const description = useRecoilValue(descriptionState);
  const digitalAddress = useRecoilValue(digitalAddressState);
  const ghanaCard = useRecoilValue(ghanaCardState);
  const email = useRecoilValue(emailState);
  const entityOfConcern = useRecoilValue(entityOfConcernState);
  const firstName = useRecoilValue(firstNameState);
  const lastName = useRecoilValue(lastNameState);
  const phoneNumber = useRecoilValue(phoneNumberState);
  const policyNumber = useRecoilValue(policyNumberState);
  const residentialAddress = useRecoilValue(residentialAddressState);
  const complaint = useRecoilValue(complaintTypeState);
  const regulatedEntityID = useRecoilValue(regulatedEntityIDState);
  const uploadedFiles = useRecoilValue(uploadedFilesState);
  const nationalIdType = useRecoilValue(nationalIdentificationTypeState);
  const region = useRecoilValue(userRegionState);
  const chosenRegion = useRecoilValue(chosenRegionState);
  const petitionerEmail = useRecoilValue(petitionEmailState);
  const petitionerType = useRecoilValue(petitionTypeState);
  const petitionerId = useRecoilValue(petitionerIdState);
  const petitionerIdType = useRecoilValue(petitionerIdentifierTypeState);
  const petitionerName = useRecoilValue(petitionFullnameState);
  const petitionerPhone = useRecoilValue(petitionerPhoneState);
  const petitionerFamilyMember = useRecoilValue(petitionFamilyMemberTypeState);
  const petitionerFamilyOther = useRecoilValue(petitionFamilyOtherState);

  const claimantOrPetitioner = useRecoilValue(claimantOrPetitionerTypeState);

  const mainClaimTypeValue =
    claimValue !== "Other" ? claimValue : otherNatureOfClaimValue;

  const [openModal, setOpenModal] = useState(false);

  const verticalLine = () => {
    return <div className="h-[22px] w-[3px] bg-purple-500"></div>;
  };

  const returnName = (first_name: string, last_name: string) => {
    if (first_name !== "" && last_name !== "") {
      return `${first_name} ${last_name}`;
    } else if (first_name) {
      return first_name;
    } else {
      return last_name;
    }
  };

  const returnPetitionerType =
    petitionerType !== "family"
      ? petitionerType
      : `${petitionerType}(${
          petitionerFamilyMember !== "other"
            ? petitionerFamilyMember
            : petitionerFamilyOther
        })`;

  useEffect(() => {
    if (uploadedFiles.length === 1) {
      setIdentifiers((prevState) => [
        {
          documentId: uploadedFiles[0].id,
        },
        ...prevState,
      ]);
    }

    if (uploadedFiles.length > 1) {
      let docs: IIdentifiers[] = [];
      for (let i = 0; i < uploadedFiles.length; i++) {
        docs.push({ documentId: uploadedFiles[i].id });
      }
      setIdentifiers((prevState) => [...docs, ...prevState]);
    }
  }, []);

  const [addTicket, { data, loading, error }] = useMutation(ADD_TICKET, {
    variables: {
      object: {
        sourceChannel: "CLIENT",
        officeId: region,
        NationalId: ghanaCard,
        vehicle_number,
        IdType: nationalIdType,
        pertitioner_name: petitionerName,
        petitioner_id_type: petitionerIdType,
        petitioner_phone_number: petitionerPhone,
        petitioner_email: petitionerEmail,
        petitioner_id_card: petitionerId,
        petitioner_type: returnPetitionerType,
        name: returnName(firstName, lastName),
        caseType,
        claimTypeValue: mainClaimTypeValue,
        claimType: claimType,
        contactNumber: phoneNumber,
        policyNumber,
        residentialAddress,
        digitalAddress,
        dateOfIncidence: dateOfIncident ? dateOfIncident : undefined,
        email,
        complainantType: "INDIVIDUAL",
        ticketType: complaint,
        description,
        regulatedEntityId:
          complaint === "PETITION" ? regulatedEntityID : undefined,
        ComplaintDocuments: {
          data: uploadedFiles.length === 0 ? [] : identifiers,
        },
      },
    },
    onError(error) {
      toast.error("Could not create complaint at this time, try again later");
      // console.log("ERROR FROM SUBMITTING", error);
    },
    onCompleted(data) {
      setticketIdentifier(data.insert_nic_ccms_Complaint_one.id);
      setStep(5);

      // empty form states
      resetNameState();
      resetEmailState();
      resetPhoneState();
      resetDescription();
      resetDigitalAdd();
      restResidentialAdd();
      resetDateOfIncident();
      resetClaimType();
      resetClaimValue();
      resetEntityOfConcern();
      resetEntityID();
      resetFilesToBeUploaded();
      resetPolicyNumber();
      resetUploadedFiles();
      resetContactPersonName();
      resetContactPersonPhone();
      resetCaseType();
      resetGhanaCard();
      resetIndividualRegion();
      resetIndividualVehicle();
    },
  });

  function onCloseModal() {
    setOpenModal(false);
  }

  function onProceedToSubmitComplaint() {
    // submit complaint here
    setOpenModal(false);
    addTicket();
  }
  function submitComplaint() {
    setOpenModal(true);
  }

  return (
    <div className="flex w-full flex-col px-6">
      <h2 className="text-center font-heading text-2xl font-semibold text-gray-primary">
        Preview
      </h2>
      <p className="my-6 text-center font-heading text-gray-primary">
        Please make sure all details are correct before submitting
      </p>
      <div className="mb-5 flex flex-col items-center justify-between md:flex-row">
        <div className="w-full md:w-1/3">
          <OutlineBtn onClick={() => setStep(3)}>Previous</OutlineBtn>
        </div>

        <div className="mt-4 w-full md:mt-0 md:w-auto">
          <SolidBtn onClick={() => submitComplaint()}>
            {loading ? (
              <div className="flex items-center justify-center">
                <ThreeDots height={25} width={40} color="white" radius={3} />
              </div>
            ) : (
              <span>Submit Complaint</span>
            )}
          </SolidBtn>
        </div>
      </div>
      {/* information display */}
      <div className="max-h-[35rem] overflow-y-scroll rounded-[5px] bg-[rgba(0,0,0,0.02)] p-[40px]">
        {/* Personal Info Display */}
        <div className="flex flex-col gap-y-[40px]">
          <div className="flex items-center gap-x-[10px]">
            {verticalLine()}
            <h3 className="font-alt text-xl font-bold text-purple-primary">
              Personal Information
            </h3>
          </div>
          {/* main data */}
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay headerInfo="First Name" subInfo={firstName} />
            <PreviewInfoDisplay headerInfo="Last Name" subInfo={lastName} />
          </div>
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay headerInfo="Email" subInfo={email} />
            <PreviewInfoDisplay
              headerInfo="Phone Number"
              subInfo={phoneNumber}
            />
          </div>
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay
              headerInfo="Residential Address"
              subInfo={residentialAddress}
            />
            <PreviewInfoDisplay
              headerInfo="Digital Address"
              subInfo={digitalAddress}
            />
          </div>
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay
              headerInfo="Type of ID"
              subInfo={nationalIdType}
            />
            <PreviewInfoDisplay
              headerInfo={nationalIdType}
              subInfo={ghanaCard}
            />
          </div>
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay headerInfo="Region" subInfo={chosenRegion} />
          </div>
        </div>
        {/* Ticket Category */}
        <div className="mt-[40px] flex flex-col gap-y-[40px]">
          <div className="flex gap-x-[10px]">
            {verticalLine()}
            <h3 className="font-alt text-xl font-bold text-purple-primary">
              Ticket Category
            </h3>
          </div>

          {/* main data */}
          <div className="flex justify-between [@media(max-width:425px)]:flex-col">
            <PreviewInfoDisplay headerInfo="Ticket Type" subInfo={complaint} />
            <PreviewInfoDisplay
              headerInfo="Date of Incident"
              subInfo={dateOfIncident}
            />
          </div>
        </div>

        {/* Ticket Details */}
        <div className="mt-[40px] flex flex-col gap-y-[40px]">
          <div className="flex gap-x-[10px]">
            {verticalLine()}
            <h3 className="font-alt text-xl font-bold text-purple-primary">
              Ticket Details
            </h3>
          </div>

          {/* main data */}
          <div className="max-w-[640px]">
            <PreviewInfoDisplay
              headerInfo="Description"
              subInfo={description}
            />
          </div>
          {complaint === "PETITION" ? (
            <>
              <div className="flex justify-between [@media(max-width:425px)]:flex-col">
                <PreviewInfoDisplay
                  headerInfo="Entity of Concern"
                  subInfo={entityOfConcern}
                />
                <PreviewInfoDisplay
                  headerInfo="Complaint Type"
                  subInfo={complaint}
                />
              </div>
              <div className="flex justify-between [@media(max-width:425px)]:flex-col">
                <PreviewInfoDisplay
                  headerInfo="Policy Number"
                  subInfo={policyNumber}
                />

                <PreviewInfoDisplay
                  headerInfo="Claim Type"
                  subInfo={claimType}
                />
                <PreviewInfoDisplay
                  headerInfo="Nature of Claim"
                  subInfo={mainClaimTypeValue}
                />
              </div>
            </>
          ) : (
            <div className="flex justify-between [@media(max-width:425px)]:flex-col">
              <PreviewInfoDisplay headerInfo="Case Type" subInfo={caseType} />
            </div>
          )}
        </div>

        {/* Petioner Information */}

        {claimantOrPetitioner !== "claimant" && (
          <div className="mt-[40px] flex flex-col gap-y-[40px]">
            <div className="flex gap-x-[10px]">
              {verticalLine()}
              <h3 className="font-alt text-xl font-bold text-purple-primary">
                Petitioner Information
              </h3>
            </div>

            {/* main data */}

            <div className="flex justify-between [@media(max-width:425px)]:flex-col">
              <PreviewInfoDisplay
                headerInfo="Petitioner Type"
                subInfo={petitionerType}
              />
              <PreviewInfoDisplay
                headerInfo="Petitioner Name"
                subInfo={petitionerName}
              />
            </div>
            <div className="flex justify-between [@media(max-width:425px)]:flex-col">
              <PreviewInfoDisplay
                headerInfo="Petitioner ID Type"
                subInfo={petitionerIdType}
              />
              <PreviewInfoDisplay
                headerInfo="Petitioner ID"
                subInfo={petitionerId}
              />
              <PreviewInfoDisplay
                headerInfo="Petitioner Email"
                subInfo={petitionerEmail}
              />
            </div>
            <div className="flex justify-between [@media(max-width:425px)]:flex-col">
              <PreviewInfoDisplay
                headerInfo="Petitioner Family Type"
                subInfo={petitionerFamilyMember}
              />
              <PreviewInfoDisplay
                headerInfo="Petitioner Phone"
                subInfo={petitionerPhone}
              />
            </div>
            <div className="flex justify-between [@media(max-width:425px)]:flex-col">
              <PreviewInfoDisplay
                headerInfo="Petitioner Family Other"
                subInfo={petitionerFamilyOther}
              />
            </div>
          </div>
        )}

        {/* Documents */}
        <div className="mt-[40px] flex flex-col gap-y-[40px]">
          <div className="flex gap-x-[10px]">
            {verticalLine()}
            <h3 className="font-alt text-xl font-bold text-purple-primary">
              Documents
            </h3>
          </div>

          {/*  */}
          <div className="flex flex-col items-start space-y-2 [@media(max-width:425px)]:flex-col">
            {uploadedFiles.map((f, i) => (
              <div key={i}>
                <PreviewInfoDisplay
                  headerInfo={`Document ${i + 1}`}
                  subInfo={f.fileName}
                  flexProp="col"
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={onCloseModal}
        onProceed={onProceedToSubmitComplaint}
      >
        {/* <h2>Hello, do you certify this shit?</h2> */}
        <p>
          I certify that my answers are true and accurate to the best of my
          knowledge{" "}
        </p>
      </Modal>
    </div>
  );
}
