import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import { INPUTTYPES } from "../../types";
import Header from "./Header";
import { InputComponent } from "./inputs";
import { OutlineBtn, SolidBtn } from "./buttons";
import { isEmail } from "../../utils/email";

import {
  firstNameState,
  lastNameState,
  emailState,
  phoneNumberState,
  residentialAddressState,
  digitalAddressState,
  ghanaCardState,
  nationalIdentificationTypeState,
  userRegionState,
  chosenRegionState,
} from "../../recoil/individual";
import { FETCH_REGIONAL_OFFICES } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { claimantOrPetitionerTypeState } from "../../recoil/onboarding";

interface IOffice {
  name: string;
  id: string;
  __typename: string;
}

const IDTypeList = [
  "Ghana Card",
  "Voter ID",
  "TIN",
  "Health Insurance",
  "Driver's License",
  "Passport Number",
];

export default function UserInfo() {
  const [firstName, setFirstName] = useRecoilState(firstNameState);
  const [lastName, setLastName] = useRecoilState(lastNameState);
  const [email, setEmail] = useRecoilState(emailState);
  const [ghanaCard, setGhanaCard] = useRecoilState(ghanaCardState);
  const [phoneNumber, setPhoneNumber] = useRecoilState(phoneNumberState);
  const [residentialAddress, setResidentialAddress] = useRecoilState(
    residentialAddressState
  );
  const [digitalAddress, setDigitalAddress] =
    useRecoilState(digitalAddressState);
  const [nationalIdType, setNationalIdType] = useRecoilState(
    nationalIdentificationTypeState
  );
  const [region, setRegion] = useRecoilState(userRegionState);
  const [selectedRegion, setSelectedRegion] = useRecoilState(chosenRegionState);

  // create formError State
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cardErr, setCardErr] = useState(false);
  const [ghanaCardError, setGhanaCardError] = useState(false);
  const [individualPhoneErr, setIndividualPhoneErr] = useState(false);

  const navigate = useNavigate();
  const claimantOrPetitioner = useRecoilValue(claimantOrPetitionerTypeState);

  // import functions to empty state

  const onCreateCompalint = () => {
    if (
      firstName.trim().length === 0 ||
      lastName.trim().length === 0 ||
      phoneNumber.trim().length === 0 ||
      residentialAddress.trim().length === 0 ||
      nationalIdType.trim().length === 0 ||
      ghanaCard.trim().length === 0 ||
      region.trim().length === 0
    ) {
      return setError(true);
    } else if (ghanaCardError) {
      setCardErr(true);
      return;
    } else {
      navigate("/complaint/single/details");
    }
  };

  // function to empty form states when user clicks on cancel button
  function emtpyFormStates() {
    if (claimantOrPetitioner === "claimant") {
      navigate("/landing");
    } else {
      navigate("/onboarding");
    }
  }

  function handleEmailInput(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);

    if (!isEmail(e.target.value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }

  function handlePhoneInput(e: React.ChangeEvent<HTMLInputElement>) {
    setPhoneNumber(e.target.value);

    if (e.target.value.length === 10) {
      setIndividualPhoneErr(false);
    } else {
      setIndividualPhoneErr(true);
    }
  }

  function handleIDChange(e: React.ChangeEvent<HTMLInputElement>) {
    setGhanaCard(e.currentTarget.value);

    if (nationalIdType === "Ghana Card") {
      const splitId = e.target.value.split("-");
      if (
        splitId[0] !== "GHA" ||
        splitId[1]?.length !== 9 ||
        splitId[2]?.length !== 1
      ) {
        setGhanaCardError(true);
      } else {
        setGhanaCardError(false);
      }
    } else {
      setGhanaCardError(false);
    }
  }
  const { data: allOffices } = useQuery(FETCH_REGIONAL_OFFICES, {
    fetchPolicy: "cache-and-network",
    onError(error) {},
    onCompleted(data) {},
  });

  function getSelectedRegion(identifier: string) {
    setSelectedRegion(" ");
    const chosenRegion = allOffices?.Office?.find(
      (office: IOffice) => office?.id === identifier
    );
    if (chosenRegion) {
      setSelectedRegion(chosenRegion?.name);
    }
  }

  return (
    <section className="mx-auto min-h-screen max-w-[100rem] px-2">
      <Header />
      <section className="mx-auto flex h-full max-w-4xl flex-col items-stretch justify-between px-6 md:px-16">
        <div className="">
          <h2 className="font-heading text-2xl font-semibold text-gray-primary md:text-center">
            Victim's Information
          </h2>
          <p className="my-6 font-heading text-gray-primary md:text-center">
            Enter details in this form to submit a complaint/petition
          </p>
          <div className="mt-10 flex flex-col items-center justify-between md:flex-row">
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="first_name">
                First Name <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                placeholder="John"
                name="first_name"
                classNames="py-5 w-full"
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
                style={{
                  border: error && firstName === "" ? "1px solid red" : "",
                }}
              />
            </div>
            <div className="mt-10 flex w-full flex-col  gap-y-[12px] md:mx-0 md:mt-0 md:w-[47%]">
              <label htmlFor="last_name">
                Last Name <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                placeholder="Doe"
                name="last_name"
                classNames="py-5 w-full"
                value={lastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
                style={{
                  border: error && lastName === "" ? "1px solid red" : "",
                }}
              />
            </div>
          </div>
          <div className="mt-10 flex flex-col items-center justify-between md:flex-row">
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="email">Email</label>
              <InputComponent
                type={INPUTTYPES.email}
                placeholder="Email"
                name="email"
                classNames="py-5"
                value={email}
                onChange={(e) => handleEmailInput(e)}
                style={{
                  border: email !== "" && emailError ? "1px solid red" : "",
                }}
              />
            </div>
            <div className="mt-10 flex w-full flex-col  gap-y-[12px] md:mx-0 md:mt-0 md:w-[47%]">
              <label htmlFor="phone_number">
                Phone Number <span className="text-red-500">*</span>
              </label>

              <InputComponent
                type={INPUTTYPES.number}
                placeholder="Enter your phone number"
                name="phone"
                classNames="py-5"
                value={phoneNumber}
                onChange={(e) => handlePhoneInput(e)}
                style={{
                  border:
                    (error && phoneNumber.trim().length === 0) ||
                    individualPhoneErr
                      ? "1px solid red"
                      : "",
                }}
              />
            </div>
          </div>

          <div className="mt-10 flex flex-col items-center justify-between md:flex-row">
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="residential_address">
                Residential Address <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                placeholder="Residential Address"
                name="residential_address"
                classNames="py-5"
                value={residentialAddress}
                onChange={(e) => setResidentialAddress(e.currentTarget.value)}
                style={{
                  border:
                    error && residentialAddress === "" ? "1px solid red" : "",
                }}
              />
            </div>
            <div className="mt-10 flex w-full flex-col  gap-y-[12px] md:mx-0 md:mt-0 md:w-[47%]">
              <label htmlFor="digital_address">Digital Address</label>
              <InputComponent
                type={INPUTTYPES.text}
                placeholder="Digital Address"
                name="digital_address"
                classNames="py-5"
                value={digitalAddress}
                onChange={(e) => setDigitalAddress(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="mt-10 flex flex-col md:space-y-0 space-y-8 items-center justify-between md:flex-row">
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="residential_address">
                Region <span className="text-red-500">*</span>
              </label>
              <select
                name="region"
                id="region"
                value={region}
                onChange={(e) => {
                  setRegion(e.target.value);
                  getSelectedRegion(e.target.value);
                }}
                className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                style={{
                  border:
                    error && region.trim().length === 0 ? "1px solid red" : "",
                }}
              >
                <option value="" disabled>
                  Select Your Region
                </option>
                {allOffices?.Office?.filter(
                  (v: IOffice) => v.name !== "HEAD OFFICE"
                ).map((region: IOffice) => (
                  <option key={region?.id} value={region?.id}>
                    {region?.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="residential_address">
                Type of ID<span className="text-red-500">*</span>
              </label>
              <select
                name="idType"
                id="IdType"
                value={nationalIdType}
                onChange={(e) => setNationalIdType(e.target.value)}
                className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                style={{
                  border:
                    error && nationalIdType.trim().length === 0
                      ? "1px solid red"
                      : "",
                }}
              >
                <option value="" disabled>
                  Select ID Type
                </option>
                {IDTypeList.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-10 flex flex-col items-center justify-between md:flex-row">
            <div className="flex w-full flex-col gap-y-[12px]  md:mx-0 md:w-[47%]">
              <label htmlFor="idVal">
                {nationalIdType === "" ? "ID Type Value" : nationalIdType}{" "}
                <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                placeholder={
                  nationalIdType.trim().length === 0
                    ? "ID Type Value"
                    : nationalIdType
                }
                name="idVal"
                classNames="py-5"
                value={ghanaCard}
                onChange={(e) => handleIDChange(e)}
                style={{
                  border:
                    (error && ghanaCard.trim().length === 0) ||
                    cardErr ||
                    ghanaCardError
                      ? "1px solid red"
                      : "",
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-[6rem] mb-8 flex items-center justify-between md:mt-[20%]">
          <div className="w-2/5 md:w-1/3">
            <OutlineBtn onClick={emtpyFormStates}>Back</OutlineBtn>
          </div>
          <div className="w-[55%] md:w-1/3">
            <SolidBtn onClick={onCreateCompalint}>Create Complaint</SolidBtn>
          </div>
        </div>
      </section>
    </section>
  );
}
