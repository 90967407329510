import { ChevronDown, ChevronUp } from "lucide-react";
import ReactMarkdown from "react-markdown";
import { useCallback, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import markdownToHtml from "../utils/markdownToHTML";
import { Link, useNavigate } from "react-router-dom";

interface IHelpItems {
  id: string;
  title: string;
  description: string;
  main: string;
  publishedAt: Date;
}

export default function HelpPage() {
  const [helpItems, setHelpItems] = useState<IHelpItems[]>([]);
  const [idx, setIdx] = useState<null | number>(null);
  const [actions, setActions] = useState({
    loading: false,
    isError: false,
  });
  const navigate = useNavigate();
  const fetchHelpItems = useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_STRAPI_API}?populate=*`;

    setActions((v) => ({ ...v, loading: true }));
    const resp = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
      },
    });

    const data = await resp.json();

    // console.log("resp", resp);

    if (resp?.status === 200 && data) {
      const filteredInsurerData = data?.data?.filter(
        (e: {
          attributes: {
            ccms_model: { data: { attributes: { Title: string } } };
          };
        }) =>
          e?.attributes?.ccms_model?.data?.attributes?.Title?.trim() ===
          "CCMS Client"
      );

      // console.log(filteredInsurerData);

      const markedHtml = await markdownToHtml(
        filteredInsurerData[0]?.attributes?.main
      );

      const mainData: IHelpItems[] = filteredInsurerData?.map((v: any) => ({
        id: v.id,
        title: v.attributes?.title,
        main: markedHtml,
        description: v.attributes?.description,
        publishedAt: new Date(v.attributes.publishedAt)
          .toDateString()
          .toLocaleString()
          .split("T")[0],
      }));

      setHelpItems(mainData);
      setActions((v) => ({ ...v, loading: false }));

      // console.log(actions?.loading);
    }
  }, []);

  useEffect(() => {
    fetchHelpItems();
  }, [fetchHelpItems]);

  // if (actions.loading) {
  //   return (
  //     <div className="-h-full flex w-full items-center justify-center">
  //       <ThreeDots color="#59285F" />
  //     </div>
  //   );
  // }
  return (
    <>
      {actions?.loading ? (
        <div className="flex h-screen w-full items-center justify-center">
          <div className="flex h-full w-full items-center justify-center">
            <ThreeDots color="#59285F" />
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="bg-purple-primary py-5 text-center font-bold text-white ">
            NIC-CCMS Help Guide
          </div>
          <section className="tablets:px-7 phone:px-3 tablets:top-20 phone:pb-4 relative">
            <div className="border-gray-secondary mt-2 grid grid-cols-3 items-start gap-4 gap-y-7 border-b py-4 px-10 font-heading [@media(max-width:700px)]:grid-cols-1 [@media(max-width:700px)]:items-start">
              {helpItems?.map((e) => (
                <div
                  className=" h-full rounded-lg border-2 py-3.5 px-2 hover:cursor-pointer hover:bg-gray-100 "
                  onClick={() => {
                    navigate(`/help/${e?.title}`, {
                      state: e,
                    });
                  }}
                >
                  <p className="font-bold">{e?.title}</p>
                  <p>{e?.description}</p>
                </div>
              ))}
            </div>
          </section>
        </>
      )}
    </>
    // <div className="pb-4">
    //   <div className="flex items-center py-12 px-6 md:px-16">
    //     <img
    //       src="/assets/images/logo.png"
    //       alt="Landing"
    //       className="h-full w-10 rounded object-cover"
    //     />
    //     <h2 className="ml-2 text-xs font-bold uppercase leading-4">
    //       <span>National</span> <br />
    //       <span>Insurance</span> <br />
    //       <span>Commission</span> <br />
    //     </h2>
    //   </div>

    //   <div className="flex space-x-10 px-6 md:px-16">
    //     <div className="space-y-2">
    //       {helpItems.map((v, i) => (
    //         <div key={v.title}>
    //           <div
    //             className="mb-2 flex cursor-pointer items-center justify-between transition"
    //             onClick={() => {
    //               if (idx === i) {
    //                 setIdx(null);
    //                 return;
    //               }

    //               setIdx(i);
    //             }}
    //           >
    //             <h4 className="text-lg font-semibold">{v.title}</h4>
    //             {/* {idx === i ? (
    //               <ChevronUp size={14} />
    //             ) : (
    //               <ChevronDown size={14} />
    //             )} */}
    //           </div>
    //           <p
    //             // className={`${
    //             //   idx === i ? "block transition-all duration-300" : "hidden"
    //             // } text-sm text-gray-500`}
    //             className="text-sm text-gray-500"
    //           >
    //             <ReactMarkdown>{v.description}</ReactMarkdown>
    //           </p>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </div>
  );
}
