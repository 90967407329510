import Header from "../components/ui/Header";
import { useNavigate } from "react-router-dom";
import StatusTracker from "../components/ui/others/StatusTracker";
import { useRecoilValue } from "recoil";
import {
  ticketStatusState,
  nameOfStatusHolder,
  entityResponsible,
} from "../recoil/status";

export default function TicketStatus() {
  const status = useRecoilValue(ticketStatusState);
  const name = useRecoilValue(nameOfStatusHolder);

  const navigate = useNavigate();

  function onDone() {
    navigate("/");
  }

  return (
    <section className="mx-auto min-h-screen max-w-[100rem] px-2">
      <Header />
      {/* <div className="xl:fixed hidden xl:block top-64 left-48">
        <StatusTracker status={status} />
      </div> */}
      <div className="font-heading max-w-2xl xl:mr-[22rem] px-6 md:px-16 flex flex-col items-stretch mx-auto h-[30rem] mt-10">
        <h2 className="text-gray-primary text-2xl font-semibold sm:text-center">
          Welcome, {name}
        </h2>
        <div className="mt-12 p-4 mx-auto w-full max-w-xl rounded-sm shadow-100">
          <p className="text-lg">The current stage of your complaint is:</p>
          <button className="text-[#00A72D] bg-[#00A72D33] focus:ring-0 font-semibold rounded text-sm px-10 py-2.5 mr-2 my-5 focus:outline-none cursor-default">
            {status}
          </button>
          <p className="text-lg mt-4 mb-2">Currently handled by:</p>
          <p className="font-bold text-lg mt-4 mb-2">
            National Insurance Commission
          </p>
        </div>
        <div className="mx-auto mt-14 w-full">
          <button
            className="text-base text-white bg-purple-primary font-semibold rounded-[5px] mb-6 lg:mb-[50px] py-3 px-8 w-full lg:px-24"
            onClick={onDone}
          >
            Done
          </button>
        </div>
      </div>
    </section>
  );
}
