import { useLazyQuery } from "@apollo/client";
import { OutlineBtn, SolidBtn } from "../components/ui/buttons";
import Header from "../components/ui/Header";
import { InputComponent } from "../components/ui/inputs";
import { INPUTTYPES } from "../types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FETCH_TICKET_STATUS } from "../graphql/queries";
import {
  entityResponsible,
  nameOfStatusHolder,
  ticketStatusState,
} from "../recoil/status";
import { useRecoilState } from "recoil";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

export default function CheckTicketStatus() {
  const navigate = useNavigate();

  const [ticketStatus, setTicketStatus] = useRecoilState(ticketStatusState);
  const [ticketHolder, setTicketHolder] = useRecoilState(nameOfStatusHolder);

  const [ticketNumberInput, setTicketNumberInput] = useState("");
  const [error, setError] = useState(false);

  const [fetchStatus, { loading }] = useLazyQuery(FETCH_TICKET_STATUS, {
    variables: {
      _eq: ticketNumberInput,
    },
    onCompleted(data) {
      if (data) {
        setTicketStatus(data?.nic_ccms_ComplaintStatus[0]?.status);
        setTicketHolder(data?.nic_ccms_ComplaintStatus[0]?.Complaint?.name);

        navigate("/status");
      }
    },
    onError(error) {
      toast.error("Unexpected error try again later");
    },
  });

  function onCheckStatus() {
    if (ticketNumberInput.trim().length === 0) {
      return setError(true);
    } else {
      fetchStatus();
    }
  }

  // function to empty form states when user clicks on cancel button
  // const navigate = useNavigate();
  function emtpyFormStates() {
    setTicketNumberInput("");
    navigate("/");
  }

  // TODO
  // 1. If no such ticket exists, show a toast letting the user no how he messed up.

  return (
    <section className="mx-auto min-h-screen max-w-[100rem] px-2">
      <Header />
      <section className="max-w-2xl px-6 md:px-16 flex flex-col justify-between items-stretch mx-auto h-full">
        <div className="">
          <h2 className="text-gray-primary text-2xl font-heading font-semibold md:text-center">
            Check Complaint/Petition Status
          </h2>
          <p className="text-gray-primary font-heading my-6 md:text-center">
            Provide the <span className="font-semibold">ticket number</span> to
            check the status of your complaint/petition
          </p>
          <div className="flex items-center justify-between mt-10 flex-col md:flex-row">
            <div className="flex flex-col w-full md:mx-0 gap-y-[12px]">
              <label>
                Ticket Number <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type={INPUTTYPES.text}
                placeholder="NIC/MPET/*****/****"
                classNames="py-5 w-full"
                value={ticketNumberInput}
                onChange={(e) => setTicketNumberInput(e.currentTarget.value)}
                style={{
                  border:
                    error && ticketNumberInput === "" ? "1px solid red" : "",
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-[6rem] mb-8 md:mt-[20%] flex justify-between items-center">
          <div className="w-2/5 md:w-1/3">
            <OutlineBtn onClick={emtpyFormStates}>Cancel</OutlineBtn>
          </div>
          <div className="w-[55%] md:w-1/3">
            {loading ? (
              <div className="flex items-center justify-center">
                <ThreeDots height={25} width={40} color="#59285F" radius={3} />
              </div>
            ) : (
              <SolidBtn onClick={() => onCheckStatus()}>Check Status</SolidBtn>
            )}
          </div>
        </div>
      </section>
    </section>
  );
}
