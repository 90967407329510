import React from "react";
import ReactDOM from "react-dom";

import { SolidBtn, OutlineBtn } from "./ui/buttons";

interface IModal {
  open: boolean;
  onProceed: () => void;
  onClose: () => void;
  onBiggerWidth?: boolean;
  children: React.ReactNode;
  cancelText?: string;
  okText?: string;
  showProceed?: boolean;
}

export default function Modal({
  open,
  children,
  onClose,
  onProceed,
  onBiggerWidth,
  cancelText,
  okText,
  showProceed = true,
}: IModal) {
  if (!open) return null;
  return ReactDOM.createPortal(
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 z-[1000] bg-[rgba(0,0,0,0.7)]"></div>
      <div
        className={`fixed top-[50%] left-[50%] z-[1000] -translate-y-2/4 -translate-x-2/4 rounded-[5px] bg-white ${
          onBiggerWidth ? "max-h-[550px] max-w-[800px]" : "max-w-[350px]"
        } p-[20px] ${onBiggerWidth ? "" : "text-center"} overflow-y-scroll`}
      >
        {children}
        <div className="mt-4 flex   gap-x-[15px]">
          <>
            {!showProceed ? (
              <SolidBtn onClick={onClose}>
                {cancelText ? cancelText : "No"}
              </SolidBtn>
            ) : (
              <OutlineBtn onClick={onClose}>
                {cancelText ? cancelText : "No"}
              </OutlineBtn>
            )}
          </>
          {showProceed && (
            <SolidBtn onClick={onProceed}>
              {okText ? okText : "Proceed"}
            </SolidBtn>
          )}
        </div>
      </div>
    </>,
    document.getElementById("portal")!
  );
}
