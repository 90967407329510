import { atom } from "recoil";

// step state
export const stepState = atom<number>({
  key: "stepCorporate",
  default: 1,
});

// corporate info
export const businessNameState = atom<string>({
  key: "businessName",
  default: "",
});
export const businessAddressState = atom<string>({
  key: "businessAddress",
  default: "",
});
export const businessPhoneNumState = atom<string>({
  key: "businessPhoneNum",
  default: "",
});
export const businessEmailState = atom<string>({
  key: "businessEmail",
  default: "",
});
export const contactPersonPhoneState = atom<string>({
  key: "contactPersonPhone",
  default: "",
});
export const contactPersonNameState = atom<string>({
  key: "contactPersonName",
  default: "",
});
export const ghanaCardState = atom<string>({
  key: "ghanaCard",
  default: "",
});
// corporate ticket details
export const complaintTypeState = atom<string>({
  key: "complaintTypeCorporate",
  default: "",
});

export const corporateCaseTypeState = atom<string>({
  key: "corporateCase",
  default: "",
});

export const dateOfIncidentState = atom<string>({
  key: "dateOfIncident",
  default: "",
});

export const descriptionState = atom<string>({
  key: "description",
  default: "",
});

export const entityOfConcernState = atom<string>({
  key: "entityOfConcern",
  default: "",
});

export const claimTypeState = atom<string>({
  key: "claimTypeCorporate",
  default: "",
});

export const claimTypeStateValue = atom<string>({
  key: "claimValueCorporate",
  default: "",
});
export const policyNumberState = atom<string>({
  key: "policyNumber",
  default: "",
});
export const regulatedEntityIdState = atom<string>({
  key: "regulatedEntity",
  default: "",
});

export const ticketIdState = atom<string>({
  key: "ticketId",
  default: "",
});

export const ticketNumberState = atom<string>({
  key: "ticketNumbState",
  default: "",
});

// this state holds the currently uploaded files in
// the browser. it is different from the one below
export const selectedDocumentsState = atom<File[]>({
  key: "selectedDocuments",
  default: [],
});

// this state holds files returned from the
// server upon successful upload
export const uploadedFilesState = atom<{ id: string; fileName: string }[]>({
  key: "uploadedFiles",
  default: [],
});

export const filesToBeUploadedState = atom<
  { id: string | number; fileName: string; file: File }[]
>({
  key: "uploadingFiles",
  default: [],
});

export const nationalIDState = atom<string>({
  key: "nationalID",
  default: "",
});
export const IDTypeState = atom<string>({
  key: "idOfGhanaian",
  default: "",
});

export const corporateRegionState = atom<string>({
  key: "fromRegion",
  default: "",
});

export const selectedRegionState = atom<string>({
  key: "selectedRegionCorporate",
  default: "",
});
export const otherCorporateClaimNatureState = atom<string>({
  key: "corporatedClaimNatureOther",
  default: "",
});

export const vehicleNumMotorState = atom<string>({
  key: "vehicle_num_motor",
  default: "",
});
