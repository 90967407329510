import { gql } from "@apollo/client";
export const FETCH_ENTITY = gql`
  query MyQuery {
  RegulatedEntity(where: {name: {_neq: "NIC"}}) {
    id
    name
    entityType
    type
  }
}
`;

export const FETCH_TICKET_NUMBER = gql`
  query getTicetNumber($id: uuid!) {
    nic_ccms_Complaint_by_pk(id: $id) {
      ticketNumber
      ticketType
      id
    }
  }
`;

export const FETCH_TICKET_STATUS = gql`
  query getStatus($_eq: String!) {
    nic_ccms_ComplaintStatus(
      where: {
        Complaint: { ticketNumber: { _eq: $_eq } }
        isActive: { _eq: true }
      }
    ) {
      isActive
      status
      id
      Complaint {
        name
        RegulatedEntity {
          name
        }
      }
    }
  }
`;
export const FETCH_REGIONAL_OFFICES = gql`
  query MyQuery {
    Office {
      id
      name
    }
  }
`;
