import React from 'react';

interface ILabel {
	forAttribute: string;
	children: React.ReactNode;
}

interface IInput {
	type: string;
	placeholder?: string;
	name?: string;
	value?: string;
	required?: boolean;
	accept?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	classNames?: string;
	multiple?: boolean;
	style?: Object;
	max?: string | undefined;
}

export const LabelComponent = ({ forAttribute, children }: ILabel) => {
	return <label htmlFor={forAttribute}>{children}</label>;
};

const InputComponent = (props: IInput) => (
	<input
		type={props.type}
		placeholder={props.placeholder}
		name={props.name}
		onChange={props.onChange}
		value={props.value}
		required={props.required}
		multiple={props.multiple}
		className={`${props.classNames} bg-gray-100 appearance-none border border-gray-100 rounded w-full py-5 px-4 text-gray-500 leading-tight focus:outline-none focus:bg-white focus:border-purple-primary placeholder:text-gray-500 placeholder:text-[16px] placeholder:opacity-[0.5]`}
		accept={props.accept}
		style={props.style}
		max={props.max}
	/>
);

export { InputComponent };
