/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react";
import axios from "axios";
import { OutlineBtn, SolidBtn } from "../../ui/buttons";

import { ThreeDots } from "react-loader-spinner";

import { SetterOrUpdater, useRecoilState, useRecoilValue } from "recoil";

import {
  stepState,
  filesToBeUploadedState,
  complaintTypeState,
  caseTypeState,
} from "../../../recoil/individual";
import { toast } from "react-toastify";
import { allowedFileTypes } from "../../../types";

interface IFILE {
  id: string | number;
  fileName: string;
  file: File;
}

interface IFORM {
  file: any | null;
  setFile: React.Dispatch<any>;
  file1: any | null;
  setFile1: React.Dispatch<any>;
  file2: any | null;
  setFile2: React.Dispatch<any>;
  file3: any | null;
  setFile3: React.Dispatch<any>;
  file4: any | null;
  setFile4: React.Dispatch<any>;
  file5: any | null;
  setFile5: React.Dispatch<any>;
  file6: any | null;
  setFile6: React.Dispatch<any>;
  file7: any | null;
  setFile7: React.Dispatch<any>;
  file8: any | null;
  setFile8: React.Dispatch<any>;
  file9: any | null;
  setFile9: React.Dispatch<any>;
  file10: any | null;
  setFile10: React.Dispatch<any>;
  petitionfile: any | null;
  setPetitionFile: React.Dispatch<any>;
  petitionfile1: any | null;
  setPetitionFile1: React.Dispatch<any>;
  petitionfile2: any | null;
  setPetitionFile2: React.Dispatch<any>;
  petitionfile3: any | null;
  setPetitionFile3: React.Dispatch<any>;
  petitionfile4: any | null;
  setPetitionFile4: React.Dispatch<any>;
  petitionfile5: any | null;
  setPetitionFile5: React.Dispatch<any>;
  petitionfile6: any | null;
  setPetitionFile6: React.Dispatch<any>;
  petitionfile7: any | null;
  setPetitionFile7: React.Dispatch<any>;
  setUploadedFiles: SetterOrUpdater<
    {
      id: string;
      fileName: string;
    }[]
  >;
}

//switch to development in dev, switch to production locally the text string
const isProduction = process.env.NODE_ENV === "production";

const url = isProduction
  ? process.env.REACT_APP_UPLOAD_URL_PROD
  : process.env.REACT_APP_UPLOAD_URL_DEMO;


export default function StepThree({
  file,
  setFile,
  file1,
  setFile1,
  file2,
  setFile2,
  file3,
  setFile3,
  file4,
  setFile4,
  file10,
  file5,
  file6,
  file7,
  file8,
  setFile10,
  setFile5,
  setFile6,
  setFile7,
  setFile9,
  setFile8,
  file9,
  petitionfile,
  setPetitionFile,
  petitionfile1,
  setPetitionFile1,
  petitionfile2,
  setPetitionFile2,
  petitionfile3,
  setPetitionFile3,
  petitionfile4,
  setPetitionFile4,
  petitionfile5,
  setPetitionFile5,
  petitionfile6,
  setPetitionFile6,
  petitionfile7,
  setPetitionFile7,
  setUploadedFiles,
}: IFORM) {
  const [loading, setLoading] = useState(false);

  const [step, setStep] = useRecoilState(stepState);
  const complaintType = useRecoilValue(complaintTypeState);
  const caseType = useRecoilValue(caseTypeState);

  const [filesToBeUploaded, setFilesToBeUploaded] = useRecoilState(
    filesToBeUploadedState
  );

  const returnIfFileExists = (file: any) => {
    const fileExists = filesToBeUploaded.some(
      (f: any) => f.fileName === file?.name
    );
    return fileExists;
  };

  const returnRandomNum = () => {
    let min = 1;
    let max = 10;
    let randomNumber = min + Math.random() * (max - min);

    return randomNumber;
  };

  //functions to handle motor-compensation files
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("File already uploaded");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile(e.target.value);
      }
    }
  };

  function handleFile1Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("File already uploaded");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile1(e.target.value);
      }
    }
  }

  function handleFile2Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile2(e.target.value);
      }
    }
  }

  function handleFile3Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile3(e.target.value);
      }
    }
  }

  function handleFile4Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile4(e.target.value);
      }
    }
  }
  function handleFile5Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile5(e.target.value);
      }
    }
  }
  function handleFile6Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile6(e.target.value);
      }
    }
  }

  function handleFile7Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile7(e.target.value);
      }
    }
  }
  function handleFile8Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile8(e.target.value);
      }
    }
  }
  function handleFile9Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile9(e.target.value);
      }
    }
  }
  function handleFile10Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setFile10(e.target.value);
      }
    }
  }

  //file remove handlers for motor-compensation
  function fileRemoveHandler() {
    let filePath = file.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setFile(null);
  }
  function file1RemoveHandler() {
    let filePath = file1.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);

    setFile1(null);
  }
  function file2RemoveHandler() {
    let filePath = file2.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setFile2(null);
  }
  function file3RemoveHandler() {
    let filePath = file3.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setFile3(null);
  }
  function file4RemoveHandler() {
    let filePath = file4.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setFile4(null);
  }
  function file5RemoveHandler() {
    let filePath = file5.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setFile5(null);
  }
  function file6RemoveHandler() {
    let filePath = file6.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setFile6(null);
  }
  function file7RemoveHandler() {
    let filePath = file7.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setFile7(null);
  }
  function file8RemoveHandler() {
    let filePath = file8.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setFile8(null);
  }
  function file9RemoveHandler() {
    let filePath = file9.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setFile9(null);
  }
  function file10RemoveHandler() {
    let filePath = file10.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setFile10(null);
  }

  //functions to handle normal petition files
  const handlePetitionFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("File already uploaded");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setPetitionFile(e.target.value);
      }
    }
  };

  function handlePetitionFile1Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("File already uploaded");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setPetitionFile1(e.target.value);
      }
    }
  }

  function handlePetitionFile2Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setPetitionFile2(e.target.value);
      }
    }
  }

  function handlePetitionFile3Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setPetitionFile3(e.target.value);
      }
    }
  }

  function handlePetitionFile4Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setPetitionFile4(e.target.value);
      }
    }
  }
  function handlePetitionFile5Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setPetitionFile5(e.target.value);
      }
    }
  }

  function handlePetitionFile6Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setPetitionFile6(e.target.value);
      }
    }
  }

  function handlePetitionFile7Change(e: React.ChangeEvent<HTMLInputElement>) {
    if (!allowedFileTypes.includes(e.target.files![0]!.type)) {
      toast.error("Invalid file format / Only PDF or Image file is allowed");
      return;
    } else {
      if (returnIfFileExists(e.target.files![0]!)) {
        toast.error("Hello file already exists");
        return;
      } else {
        const filesUploaded: {
          id: string | number;
          fileName: string;
          file: File;
        }[] = [];
        filesUploaded.push({
          id: returnRandomNum(),
          fileName: e.target.files![0]?.name,
          file: e.target.files![0]!,
        });
        setFilesToBeUploaded((prevFiles: any) =>
          prevFiles.concat(filesUploaded)
        );
        setPetitionFile7(e.target.value);
      }
    }
  }
  //file remove handlers for petitions

  function petitionfileRemoveHandler() {
    let filePath = petitionfile.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setPetitionFile(null);
  }
  function petitionfile1RemoveHandler() {
    let filePath = petitionfile1.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setPetitionFile1(null);
  }
  function petitionfile2RemoveHandler() {
    let filePath = petitionfile2.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setPetitionFile2(null);
  }
  function petitionfile3RemoveHandler() {
    let filePath = petitionfile3.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setPetitionFile3(null);
  }
  function petitionfile4RemoveHandler() {
    let filePath = petitionfile4.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setPetitionFile4(null);
  }
  function petitionfile5RemoveHandler() {
    let filePath = petitionfile5.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setPetitionFile5(null);
  }
  function petitionfile6RemoveHandler() {
    let filePath = petitionfile6.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setPetitionFile6(null);
  }
  function petitionfile7RemoveHandler() {
    let filePath = petitionfile7.substring("C:\\fakepath\\".length);
    const newFiles = filesToBeUploaded.filter((e) => e.fileName !== filePath);
    setFilesToBeUploaded(newFiles);
    setPetitionFile7(null);
  }

  const handleNextStep = async () => {
    if (complaintType === "MOTOR-PETITION" && filesToBeUploaded?.length < 8) {
      toast.error("Please provide all required documents before proceeding");
      return;
    } else {
      if (complaintType === "PETITION" && filesToBeUploaded?.length < 1) {
        toast.error("Please provide a required documents before proceeding");
        return;
      } else {
        if (step < 6 && step !== 3) {
          setStep((prevStep) => prevStep + 1);
        }
        if (step === 3) {
          if (filesToBeUploaded.length === 0) {
            setStep((prevStep) => prevStep + 1);
          } else {
            setLoading(true);
            const fileData = filesToBeUploaded.map(
              (entry: IFILE) => entry.file
            );
            let formData = new FormData();
            for (let i = 0; i < fileData.length; i++) {
              formData.append("files", fileData[i]);
            }
            try {
              const resp = await axios.post(url as string, formData);
              let filesToBeSentToDB = [];
              for (let i = 0; i < resp.data.length; i++) {
                filesToBeSentToDB.push({
                  id: resp.data[i]?.id,
                  fileName: resp.data[i]?.fileName,
                });
              }
              setUploadedFiles(filesToBeSentToDB);
              setLoading(false);
              setStep((prevStep) => prevStep + 1);
            } catch (err: any) {
              setLoading(false);
              toast.error(
                "Sorry could not upload file at this time, try again"
              );
            }
          }
        }
      }
    }
  };
  const handlePrevious = () => {
    if (complaintType === "PETITION") {
      setStep((prev) => prev - 1);
    } else if (step > 1) {
      setStep(1);
    } else return;
  };
  return (
    <section>
      <section>
        <div className="">
          <h2 className="text-center font-heading text-2xl font-semibold text-gray-primary">
            Documents
          </h2>
          <p className="my-6 text-center font-heading text-gray-primary">
            Provide additional documents to support your claim or petition
          </p>
          {complaintType === "MOTOR-PETITION" ? (
            <div className="h-[390px] overflow-scroll [@media(max-width:500px)]:flex [@media(max-width:500px)]:flex-col [@media(max-width:500px)]:gap-6">
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">
                      {caseType === "injury"
                        ? "Original Police Accident report"
                        : "Original Police Accident report"}
                    </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {file === null ? (
                      <input
                        type="file"
                        onChange={handleFileChange}
                        value={file}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {file}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={fileRemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">
                      {caseType === "injury"
                        ? "The claimant’s National ID card, preferably Ghana card"
                        : caseType === "death"
                        ? "The Administrator(s) National ID card, preferably Ghana card."
                        : " Signed Letter Of Petition"}{" "}
                    </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {file1 === null ? (
                      <input
                        type="file"
                        onChange={handleFile1Change}
                        value={file1}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {filesToBeUploaded[1]?.fileName} */}
                          {file1}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={file1RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">
                      {" "}
                      {caseType === "injury"
                        ? "Original Medical report"
                        : "Original Death Certificate or Burial permit"}{" "}
                    </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {file2 === null ? (
                      <input
                        type="file"
                        onChange={handleFile2Change}
                        value={file2}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {file2}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={file2RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">
                      {caseType === "injury"
                        ? "Pictures of the injury with the victim’s face fully showing."
                        : "Original Statutory Declaration (minor) "}{" "}
                    </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {file3 === null ? (
                      <input
                        type="file"
                        onChange={handleFile3Change}
                        value={file3}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {selectedFiles[0]?.fileName} */}
                          {file3}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={file3RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">
                      {caseType === "injury"
                        ? "Two (2) passport sized pictures endorsed by the Medical Doctor"
                        : "Two (2) passport sized pictures of the Administrator(s)"}{" "}
                    </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {file4 === null ? (
                      <input
                        type="file"
                        onChange={handleFile4Change}
                        value={file4}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {selectedFiles[0]?.fileName} */}
                          {file4}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={file4RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">
                      {caseType === "injury"
                        ? "An affidavit of instruction/ authorization if a solicitor is involved."
                        : "Original Letters of Administration (adult)"}
                    </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {file5 === null ? (
                      <input
                        type="file"
                        onChange={handleFile5Change}
                        value={file5}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {selectedFiles[0]?.fileName} */}
                          {file5}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={file5RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">
                      {caseType === "injury"
                        ? "An affidavit of correction of name if the name on the Medical report and Police Accident report differs from that on the National Identity card. Reference should be made to the mistakes. "
                        : "Original Affidavit stating the name of the spouse and children as well as their ages."}{" "}
                    </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {file6 === null ? (
                      <input
                        type="file"
                        onChange={handleFile6Change}
                        value={file6}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {selectedFiles[0]?.fileName} */}
                          {file6}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={file6RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">
                      {caseType === "injury"
                        ? "Original Medical receipts and prescriptions (if any)."
                        : "An affidavit of correction of name if the name on the Letters of Administration and Statutory Declaration differs from that on the National Identity card. Reference should be made to the mistakes."}{" "}
                    </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {file7 === null ? (
                      <input
                        type="file"
                        onChange={handleFile7Change}
                        value={file7}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {selectedFiles[0]?.fileName} */}
                          {file7}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={file7RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">
                      {caseType === "injury"
                        ? "Repudiation letter if there is an insurance company mentioned on the Police Accident report.                        "
                        : "An affidavit to correct the deceased’s name should there be a mistake on any of the documents provided. Reference should be made to the mistakes"}{" "}
                    </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {file8 === null ? (
                      <input
                        type="file"
                        onChange={handleFile8Change}
                        value={file8}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {selectedFiles[0]?.fileName} */}
                          {file8}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={file8RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {caseType === "death" && (
                <div className="mb-6 flex items-center gap-16">
                  <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                    <div className="flex items-center justify-between">
                      <p className="mb-2 text-sm">
                        {caseType === "death" &&
                          "An affidavit of instruction/ authorization if a solicitor is involved."}
                      </p>
                      <p className="mb-3 text-end text-xs text-red-600">
                        File size must not exceed 5MB
                      </p>
                    </div>
                    <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                      {file9 === null ? (
                        <input
                          type="file"
                          onChange={handleFile9Change}
                          value={file}
                        />
                      ) : (
                        <div className="flex h-full w-full items-center justify-between">
                          <span className="text-[#407BFF] underline decoration-solid">
                            {/* {selectedFiles[0]?.fileName} */}
                            {file9}
                          </span>
                          <button
                            className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                            onClick={file9RemoveHandler}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {caseType === "death" && (
                <div className="mb-6 flex items-center gap-16">
                  <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                    <div className="flex items-center justify-between">
                      <p className="mb-2 text-sm">
                        Repudiation letter if there is an insurance company
                        mentioned on the Police Accident report.
                      </p>
                      <p className="mb-3 text-end text-xs text-red-600">
                        File size must not exceed 5MB
                      </p>
                    </div>
                    <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                      {file10 === null ? (
                        <input
                          type="file"
                          onChange={handleFile10Change}
                          value={file10}
                        />
                      ) : (
                        <div className="flex h-full w-full items-center justify-between">
                          <span className="text-[#407BFF] underline decoration-solid">
                            {/* {selectedFiles[0]?.fileName} */}
                            {file10}
                          </span>
                          <button
                            className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                            onClick={file10RemoveHandler}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="h-[390px] overflow-scroll [@media(max-width:500px)]:flex [@media(max-width:500px)]:flex-col [@media(max-width:500px)]:gap-6">
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">Signed Letter Of Petition </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {petitionfile === null ? (
                      <input
                        type="file"
                        onChange={handlePetitionFileChange}
                        value={petitionfile}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {selectedFiles[0]?.fileName} */}
                          {petitionfile}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={petitionfileRemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">National Identication Card </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {petitionfile1 === null ? (
                      <input
                        type="file"
                        onChange={handlePetitionFile1Change}
                        value={petitionfile1}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {petitionfile1}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={petitionfile1RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">Additional Document 2 </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {petitionfile2 === null ? (
                      <input
                        type="file"
                        onChange={handlePetitionFile2Change}
                        value={petitionfile2}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {selectedFiles[0]?.fileName} */}
                          {petitionfile2}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={petitionfile2RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">Additional Document 3 </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {petitionfile3 === null ? (
                      <input
                        type="file"
                        onChange={handlePetitionFile3Change}
                        value={petitionfile3}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {selectedFiles[0]?.fileName} */}
                          {petitionfile3}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={petitionfile3RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">Additional Documents 4 </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {petitionfile4 === null ? (
                      <input
                        type="file"
                        onChange={handlePetitionFile4Change}
                        value={petitionfile4}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {filesToBeUploaded[1]?.fileName} */}
                          {petitionfile4}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={petitionfile4RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">Additional Documents 5 </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {petitionfile5 === null ? (
                      <input
                        type="file"
                        onChange={handlePetitionFile5Change}
                        value={petitionfile5}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {filesToBeUploaded[1]?.fileName} */}
                          {petitionfile5}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={petitionfile5RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">Additional Documents 6 </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {petitionfile6 === null ? (
                      <input
                        type="file"
                        onChange={handlePetitionFile6Change}
                        value={petitionfile6}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {filesToBeUploaded[1]?.fileName} */}
                          {petitionfile6}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={petitionfile6RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-6 flex items-center gap-16">
                <div className="mx-auto flex w-full max-w-[500px] flex-auto flex-col">
                  <div className="flex items-center justify-between">
                    <p className="mb-2 text-sm">Additional Documents 7 </p>
                    <p className="mb-3 text-end text-xs text-red-600">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex h-[45px] items-center rounded border border-gray-300 bg-[#00000008] px-2 text-sm">
                    {petitionfile7 === null ? (
                      <input
                        type="file"
                        onChange={handlePetitionFile7Change}
                        value={petitionfile7}
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {/* {filesToBeUploaded[1]?.fileName} */}
                          {petitionfile7}
                        </span>
                        <button
                          className="h-[80%] w-[100px] cursor-pointer rounded border-none bg-purple-primary text-white"
                          onClick={petitionfile7RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="flex items-center gap-16 mb-6">
                <div className="flex-auto flex flex-col mx-auto max-w-[500px] w-full">
                  <div className="flex items-center justify-between">
                    <p className="text-sm mb-2">Additional Documents 8 </p>
                    <p className="text-red-600 text-xs mb-3 text-end">
                      File size must not exceed 5MB
                    </p>
                  </div>
                  <div className="flex items-center rounded border border-gray-300 text-sm h-[45px] bg-[#00000008] px-2">
                    {petitionfile7 === null ? (
                      <input
                        type="file"
                        onChange={handlePetitionFile7Change}
                        value={petitionfile7}
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-between">
                        <span className="text-[#407BFF] underline decoration-solid">
                          {filesToBeUploaded[1]?.fileName}
                          {petitionfile7}
                        </span>
                        <button
                          className="h-[80%] w-[100px] text-white bg-purple-primary rounded border-none cursor-pointer"
                          onClick={petitionfile7RemoveHandler}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </div>
        <div className="mt-[30%] mb-10 flex items-center justify-between px-6">
          <div className="w-2/5 md:w-1/3">
            <OutlineBtn onClick={handlePrevious}>Previous</OutlineBtn>
          </div>

          <div className="w-2/5 md:w-1/3">
            <SolidBtn onClick={handleNextStep}>
              {loading ? (
                <div className="flex items-center justify-center">
                  <ThreeDots height={25} width={40} color="white" radius={3} />
                </div>
              ) : (
                <span>Next</span>
              )}
            </SolidBtn>
          </div>
        </div>
      </section>
    </section>
  );
}
