import { X } from "lucide-react";
import React from "react";
import ReactDOM from "react-dom";

interface IModal {
  open: boolean;
  onClose: () => void;
  position?: "center-top" | "center-middle" | "top-right" | "top-left";
  children: React.ReactNode;
}

export default function HelpModalWrapper({
  open,
  children,
  onClose,
  position = "center-middle",
}: IModal) {
  if (!open) return null;
  let modalPosition;
  switch (position) {
    case "center-top":
      modalPosition = "top-0 left-1/2 -translate-x-2/4";
      break;
    case "top-right":
      modalPosition = "top-0 right-0";
      break;
    case "top-left":
      modalPosition = "top-0 left-0";
      break;
    default:
      modalPosition = "top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4";
  }
  return ReactDOM.createPortal(
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 z-[1000] bg-[rgba(0,0,0,0.7)] transition-opacity duration-300" />
      <div
        className={`fixed ${modalPosition} z-[1000] mt-10 w-[300px] scale-90 transform overflow-y-scroll rounded-[5px] bg-white py-[15px] px-[20px] transition-transform duration-300 lg:w-[600px]`}
      >
        <div className="flex flex-col">
          <button
            onClick={onClose}
            className="ml-auto p-2 hover:rounded-md hover:bg-gray-200"
          >
            <X />
          </button>
          <main className="h-full">{children}</main>
        </div>
      </div>
    </>,
    document.getElementById("portal")!
  );
}
