import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import Landing from "./pages/Landing";
import Onboarding from "./pages/Onboarding";
import UserInfo from "./components/ui/UserInfo";
import CorporateInfo from "./components/ui/CorporateInfo";
import CorporateDetails from "./pages/CorporateDetails";
import Details from "./pages/Details";
import TicketStatus from "./pages/TicketStatus";
import CheckTicketStatus from "./pages/CheckTicketStatus";
import PetitionerOnboarding from "./pages/PetitionerOnboarding";
import ComplaintTypePage from "./pages/ComplaintType";
import HelpPage from "./pages/Help";
import NotFound from "./pages/404";
import HelpPageId from "./pages/HelpPageId";

const apiKey = process.env.REACT_APP_APIKEY;
const authDomain = process.env.REACT_APP_AUTHDOMAIN;
const databaseURL = process.env.REACT_APP_DATABASEURL;
const projectId = process.env.REACT_APP_PROJECTID;
const storageBucket = process.env.REACT_APP_STORAGEBUCKET;
const messagingSenderId = process.env.REACT_APP_MESSAGINGSENDERID;
const appId = process.env.REACT_APP_APPID;
const measurementId = process.env.REACT_APP_MEASUREMENTID;

const firebaseConfig = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

function App() {
  return (
    <main className="text-gray-primary">
      <Routes >
        <Route path="/" element={<ComplaintTypePage />}  />
        <Route path="/all" element={<Onboarding />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/onboarding" element={<PetitionerOnboarding />} />
        <Route path="/complaint/single" element={<UserInfo />} />
        <Route path="/complaint/single/details" element={<Details />} />
        <Route path="/complaint/corporate" element={<CorporateInfo />} />
        <Route
          path="/complaint/corporate/corporate-details"
          element={<CorporateDetails />}
        />
        <Route path="/check-status" element={<CheckTicketStatus />} />
        <Route path="/status" element={<TicketStatus />} />
        <Route path="/help" element={<HelpPage />}></Route>
        <Route path="/help/:id" element={<HelpPageId />}></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>

      <ToastContainer theme="colored" />
    </main>
  );
}

export default App;
