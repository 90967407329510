/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useRecoilValue } from 'recoil';
import { stepState } from '../../../recoil/individual';

export default function VerticalTracker() {
	const step = useRecoilValue(stepState);

	return (
		<aside className=''>
			<div className='flex flex-col justify-center'>
				{/* first item */}
				<div className='flex'>
					<p className='bg-gray-400 rounded-full h-6 w-6 flex items-center justify-center'>
						<span>1</span>
					</p>
					<p className='ml-3'>Ticket Details</p>
				</div>
				{/* second item (step) */}
				<div className='h-11 w-[2.5px] bg-gray-300 ml-[10px] my-2 rounded'>
					{step === 1 && <div className='h-6 bg-purple-500'></div>}
					{step > 1 && <div className='h-full bg-purple-500'></div>}
				</div>
				{/* third item */}
				<div className='flex'>
					<p className='bg-gray-400 rounded-full h-6 w-6 flex items-center justify-center'>
						<span>2</span>
					</p>
					<p className='ml-3'>Documents</p>
				</div>
				{/* fourth item (step) */}
				<div className='h-11 w-[2.5px] bg-gray-300 ml-[10px] my-2 rounded'>
					{step === 2 && <div className='h-6 bg-purple-500'></div>}
					{step > 2 && <div className='h-full bg-purple-500'></div>}
				</div>
				{/* fifth item */}
				<div className='flex'>
					<p className='bg-gray-400 rounded-full h-6 w-6 flex items-center justify-center'>
						<span>3</span>
					</p>
					<p className='ml-3'>Preview</p>
				</div>
				{/* sixth item (step) */}
				<div className='h-11 w-[2.5px] bg-gray-300 ml-[10px] my-2 rounded'>
					{step === 3 && <div className='h-6 bg-purple-500'></div>}
					{step > 3 && <div className='h-full bg-purple-500'></div>}
				</div>
				{/* seventh item */}
				<div className='flex'>
					<p className='bg-gray-400 rounded-full h-6 w-6 flex items-center justify-center'>
						<span>4</span>
					</p>
					<p className='ml-3'>Submit</p>
				</div>
			</div>
		</aside>
	);
}
