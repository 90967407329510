import { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Modal from "../components/Modal";
import MotorFundDocumentsRequired from "../components/ui/others/MotorFundDocuments";
import { useRecoilState } from "recoil";
import { complaintTypeState } from "../recoil/individual";
import packageJson from "../../package.json";

export default function ComplaintTypePage() {
  const navigate = useNavigate();

  const [complaintType, setComplaintType] = useRecoilState(complaintTypeState);
  const [openModal, setOpenModal] = useState(false);
  const [openMotor, setOpenMotor] = useState(false);
  const [openInfoShow, setOpenInfoShow] = useState(false);

  const onCloseModal = () => {
    setOpenModal(false);
    setOpenInfoShow(true);
  };

  const onNormalProceed = () => {
    setOpenModal(false);
    setComplaintType("PETITION");
    navigate("/all");
  };

  
  return (
    <section className="relative mx-auto grid min-h-screen max-w-[125rem] grid-cols-2 p-2 font-heading">
      <div className="landing-bg relative col-span-2 mx-auto h-full w-full items-stretch rounded lg:col-span-1">
        <div className="h-full">
          <div className="mx-auto flex h-full max-w-3xl flex-col items-stretch py-6 px-8 text-white sm:py-10 sm:px-24 lg:justify-between">
            <>
              <Link to={"/"}>
                <div className="flex items-center">
                  <img
                    src="/assets/images/logo.png"
                    alt="Landing"
                    className="h-full w-10 rounded object-cover"
                  />
                  <h2 className="ml-2 text-xs font-bold uppercase leading-4">
                    <span>National</span> <br />
                    <span>Insurance</span> <br />
                    <span>Commission</span> <br />
                  </h2>
                </div>
              </Link>
            </>
            <div className="my-16 md:w-10/12 lg:pb-10">
              <h2 className="text-2xl font-semibold lg:text-5xl lg:leading-[70px]">
                Protecting the interests of policy holders{" "}
              </h2>
            </div>
            <div className="mt-10 mb-12 md:w-10/12 lg:hidden lg:pb-10">
              <div className="h-full text-center">
                <h3 className="mb-auto font-semibold tracking-wider text-white lg:text-2xl lg:font-bold lg:text-gray-primary">
                  Welcome to the NIC Complaints Portal
                </h3>
                <p className="py-9 font-medium text-white lg:py-14 lg:text-gray-primary">
                  Please select the type of complaint
                </p>
                <div className="mx-auto flex max-w-3xl flex-col items-center">
                  <button
                    className="mb-6 w-full rounded-[5px] bg-white py-3 px-8  text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:bg-purple-500 lg:px-24 lg:text-white"
                    onClick={() => setOpenModal(true)}
                  >
                    Normal Petition
                  </button>

                  <button
                    className="mb-6 w-full rounded-[5px] bg-white py-3 px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:bg-purple-primary lg:px-24 lg:text-white"
                    onClick={() => setOpenMotor(true)}
                  >
                    Motor Compensation Fund
                  </button>
                  <button
                    className="mb-[50px] w-full rounded-[5px] bg-white py-3 px-8 text-[16px] font-semibold text-purple-primary lg:bg-purple-500 lg:px-24 lg:text-white"
                    onClick={() => navigate("/check-status")}
                  >
                    Check Complaint/Petition Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-10 mx-auto hidden flex-col px-7 lg:relative lg:flex">
        <div className="col-span-1 mx-auto grid h-full place-content-center justify-between">
          <div className="h-full text-center">
            <h3 className="mb-auto font-semibold tracking-wider text-white lg:text-2xl lg:font-bold lg:text-gray-primary">
              Welcome to the NIC Complaints Portal
            </h3>
            <p className="py-9 font-medium text-white lg:py-14 lg:text-gray-primary">
              Please select the type of complaint
            </p>
            <div className="mx-auto flex max-w-3xl flex-col items-center">
              <button
                className="mb-6 w-full rounded-[5px] bg-purple-primary py-3  px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:px-24 lg:text-white"
                onClick={() => setOpenModal(true)}
              >
                Normal Petition
              </button>

              <button
                className="mb-6 w-full rounded-[5px] bg-purple-primary py-3 px-8 text-[16px] font-semibold text-purple-primary lg:mb-[50px] lg:px-24 lg:text-white"
                onClick={() => setOpenMotor(true)}
              >
                Motor Compensation Fund
              </button>
              <button
                className="mb-[50px] w-full rounded-[5px] bg-white py-3 px-8 text-[16px] font-semibold text-purple-primary lg:bg-purple-primary lg:px-24 lg:text-white"
                onClick={() => navigate("/check-status")}
              >
                Check Complaint/Petition Status
              </button>
            </div>
          </div>
        </div>
        <p className="mx-auto mt-auto hidden items-center text-[#959595] lg:flex">
          Powered by{" "}
          <span className="ml-1 font-semibold">Insurerity Digital</span>
        </p>
        {/* <p className="text-center">Version {packageJson?.version}</p> */}
      </div>

      <Modal
        open={openModal}
        onClose={onCloseModal}
        onProceed={onNormalProceed}
        onBiggerWidth={false}
        cancelText="No"
        okText="Yes"
      >
        <h2 className="text-base font-semibold lg:text-lg">
          Please note that you must notify the insurance company involved before
          proceeding. Have you done that?
        </h2>
      </Modal>
      <Modal
        open={openInfoShow}
        onClose={() => setOpenInfoShow(false)}
        onProceed={onNormalProceed}
        onBiggerWidth={false}
        cancelText="Okay"
        showProceed={false}
      >
        <h2 className="text-base font-semibold lg:text-lg">
          Please seek redress with the insurance company involved and only make
          a complaint if that fails.
        </h2>
      </Modal>

      <MotorFundDocumentsRequired
        onClose={() => setOpenMotor(false)}
        openModal={openMotor}
        onProceed={() => {
          setOpenMotor(false);
          setComplaintType("MOTOR-PETITION");
          navigate("/all");
        }}
      />
    </section>
  );
}
