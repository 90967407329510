import { useCallback, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import markdownToHtml from "../utils/markdownToHTML";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SolidBtn } from "../components/ui/buttons";

interface IHelpItems {
  id: string;
  title: string;
  description: string;
  main: string;
  publishedAt: Date | string;
}

export default function HelpIndexId() {
  const [helpItems, setHelpItems] = useState<IHelpItems>();
  const [notFound, setNotFound] = useState(false);
  let location = useLocation();
  const navigate = useNavigate();

  const currentPath = location?.state?.id;
  // console.log(currentPath);

  const getHelpData = async () => {
    const resp = await fetch(
      `${process.env.REACT_APP_STRAPI_API}/${currentPath}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
        },
      }
    );

    // console.log(resp);

    if (resp?.status === 404 || resp?.status === 500) {
      setNotFound(true);
      return;
    } else {
      const data = await resp?.json();

      // console.log(data);

      if (resp?.status === 200 && data) {
        // console.log(data?.data?.attributes);

        const markedHtml = await markdownToHtml(
          data?.data?.attributes?.content
        );

        const mainData: IHelpItems = {
          id: data?.data?.id,
          title: data?.data.attributes?.title,
          main: markedHtml,
          description: data?.data?.attributes?.description,
          publishedAt: new Date(data?.data?.attributes?.publishedAt)
            .toDateString()
            .toLocaleString()
            .split("T")[0],
        };

        // console.log(mainData);
        setHelpItems(mainData);
      }
    }
  };

  useEffect(() => {
    getHelpData();
  }, [currentPath]);

  if (notFound) {
    return (
      <section className="phone:px-3 tablets:top-20 phone:pb-4 relative flex w-full flex-col items-center justify-center px-7 pt-14">
        <h1 className="phone:text-[1.4rem] mb-6 text-center text-[2rem] font-semibold text-[#4F4F4F]">
          Resource not Found
        </h1>
        <div className="relative mb-6 h-[150px] w-[150px]">
          <img src="../assets/images/bro.svg" alt="404 image" />
        </div>
        <p className="phone:w-full mx-auto mb-6 w-3/4 text-center">
          It looks like we couldnt find the page you have navigated to. If this
          is an error on our part please do well to report it, otherwise
          consider going back
        </p>
        <SolidBtn classNames="w-[200px]" onClick={() => {
          navigate(-1)
        }}>
          Take me back
        </SolidBtn>
      </section>
    );
  }

  return (
    <section className="tablets:px-7 phone:px-3 tablets:top-20 phone:pb-4 relative">
      <div className="border-gray-secondary mt-10  mx-10 md:mx-48   items-start border-b py-4 font-heading [@media(max-width:700px)]:items-start">
        {
          <main
            dangerouslySetInnerHTML={{ __html: helpItems?.main ?? "" }}
            className="danger_insert mt-2"
          />
        }
      </div>
    </section>
  );
}
