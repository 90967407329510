import React, { useState } from "react";
import { SolidBtn } from "./buttons";
import HelpGuideModal from "./others/HelpGuideModal";
import { Link, useNavigate } from "react-router-dom";

export default function Header() {
  const [openModal, setOpenModal] = useState(false);
  const naviagte = useNavigate();
  
 const  handleNavigate =async () => {
    naviagte("/");
    window.location.reload();
  }
  
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center py-12 px-6 md:px-16 hover:cursor-pointer" onClick={handleNavigate}>
        <img
          src="/assets/images/logo.png"
          alt="Landing"
          className="h-full w-10 rounded object-cover"
        />
        <h2 className="ml-2 text-xs font-bold uppercase leading-4">
          <span>National</span> <br />
          <span>Insurance</span> <br />
          <span>Commission</span> <br />
        </h2>
      </div>
      <div className="max-w-[300px] p-4 hidden">
        {/* <SolidBtn onClick={() => setOpenModal(true)}>Need help?</SolidBtn> */}
      </div>

      <HelpGuideModal
        onClose={() => setOpenModal(false)}
        openModal={openModal}
        position="center-top"
      />
    </div>
  );
}
