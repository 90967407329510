import React, { useState } from "react";
import { useRecoilValue } from "recoil";

import { stepState } from "../recoil/corporate";

import Header from "../components/ui/Header";
import StepOne from "../components/steps/corporate/StepOne";
import StepTwo from "../components/steps/corporate/StepTwo";
import StepThree from "../components/steps/corporate/StepThree";
import StepFour from "../components/steps/corporate/StepFour";
import StepFive from "../components/steps/corporate/StepFive";
import VerticalTracker from "../components/ui/others/VerticalTracker";
import HorizontalTracker from "../components/ui/others/HorizontalTracker";

export default function CorporateDetails() {
  const step = useRecoilValue(stepState);
  //moto-compensation file states
  const [file, setFile] = useState<any | null>(null);
  const [file1, setFile1] = useState<any | null>(null);
  const [file2, setFile2] = useState<any | null>(null);
  const [file3, setFile3] = useState<any | null>(null);
  const [file4, setFile4] = useState<any | null>(null);
  const [file5, setFile5] = useState<any | null>(null);
  const [file6, setFile6] = useState<any | null>(null);
  const [file7, setFile7] = useState<any | null>(null);
  const [file8, setFile8] = useState<any | null>(null);
  const [file9, setFile9] = useState<any | null>(null);
  const [file10, setFile10] = useState<any | null>(null);

  //petition file states
  const [petitionfile, setPetitionFile] = useState<any | null>(null);
  const [petitionfile1, setPetitionFile1] = useState<any | null>(null);
  const [petitionfile2, setPetitionFile2] = useState<any | null>(null);
  const [petitionfile3, setPetitionFile3] = useState<any | null>(null);
  const [petitionfile4, setPetitionFile4] = useState<any | null>(null);
  const [petitionfile5, setPetitionFile5] = useState<any | null>(null);
  const [petitionfile6, setPetitionFile6] = useState<any | null>(null);
  const [petitionfile7, setPetitionFile7] = useState<any | null>(null);

  return (
    <section className="mx-auto min-h-screen max-w-[100rem] px-2">
      <Header />

      <div className="top-64 left-20 hidden xl:fixed xl:block">
        <VerticalTracker />
      </div>
      <div className="my-6 xl:hidden">
        <HorizontalTracker />
      </div>
      <section className="mx-auto flex h-full max-w-4xl flex-col items-stretch justify-between md:px-16">
        {step === 1 && <StepOne />}
        {step === 2 && <StepTwo />}
        {step === 3 && (
          <StepThree
            file={file}
            setFile={setFile}
            file1={file1}
            setFile1={setFile1}
            file2={file2}
            setFile2={setFile2}
            file3={file3}
            setFile3={setFile3}
            file4={file4}
            setFile4={setFile4}
            file5={file5}
            file6={file6}
            file7={file7}
            file10={file10}
            file8={file8}
            file9={file9}
            setFile10={setFile10}
            setFile5={setFile5}
            setFile6={setFile6}
            setFile7={setFile7}
            setFile8={setFile8}
            setFile9={setFile9}
            petitionfile={petitionfile}
            setPetitionFile={setPetitionFile}
            petitionfile1={petitionfile1}
            setPetitionFile1={setPetitionFile1}
            petitionfile2={petitionfile2}
            setPetitionFile2={setPetitionFile2}
            petitionfile3={petitionfile3}
            setPetitionFile3={setPetitionFile3}
            petitionfile4={petitionfile4}
            setPetitionFile4={setPetitionFile4}
            petitionfile5={petitionfile5}
            setPetitionFile5={setPetitionFile5}
            petitionfile6={petitionfile6}
            setPetitionFile6={setPetitionFile6}
            petitionfile7={petitionfile7}
            setPetitionFile7={setPetitionFile7}
          />
        )}
        {step === 4 && <StepFour />}
        {step === 5 && <StepFive />}
      </section>
    </section>
  );
}
