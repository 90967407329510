import React, { useState } from "react";
import { INPUTTYPES } from "../../../types";
import { useQuery } from "@apollo/client";
import { InputComponent } from "../../ui/inputs";
import { Link } from "react-router-dom";
import { OutlineBtn, SolidBtn } from "../../ui/buttons";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  stepState,
  claimTypeState,
  dateOfIncidentState,
  policyNumberState,
  entityOfConcernState,
  complaintTypeState,
  regulatedEntityIDState,
  descriptionState,
  caseTypeState,
  claimTypeValueState,
  otherNatureOfClaimState,
  numVehicleState,
} from "../../../recoil/individual";
import { FETCH_ENTITY } from "../../../graphql/queries";
interface IRegulatedEntity {
  id: string;
  name: string;
}

const claimTypeValues = {
  life: [
    "Funeral Policy Claim",
    "Surrender Value",
    "Maturity Value",
    "Unlawful Deductions",
    "Commission Payments",
    "Low Quantum",
    "Whole Life Policy",
    "Investment Policy",
    "Term Life Policy",
    "Other",
  ],
  "Non-Life": [
    "Performance Bond",
    "Counter Guarantee Bond",
    "Guarantee Bond",
    "Home Settlement Claim",
    "Fire Claim",
    "Contractors All Risk",
    "Assets All Risk",
    "Flood Claim",
    "Workmen Compensation",
    "Burglary",
    "Advance Mobilisation Bond",
    "Goods In Transit",
    "Group Life",
    "Death Claim",
    "Injury Claim",
    "Other",
  ],
  "Motor Compensation": [
    "Motor Injury Claim",
    "Motor Death Claim",
    "Motor Own Damage",
    "TPPD",
    "Motor Theft/Burglary",
    "Goods -in-transit",
    "Motor Flood Claim",
    "Other",
  ],
};

export default function StepOne() {
  const [step, setStep] = useRecoilState(stepState);

  const complaintType = useRecoilValue(complaintTypeState);
  const [dateOfIncident, setDateOfIncident] =
    useRecoilState(dateOfIncidentState);
  const [otherNatureOfClaim, setOtherNatureOfClaim] = useRecoilState(
    otherNatureOfClaimState
  );
  const [vehicle_Num, setVehicle_Num] = useRecoilState(numVehicleState);
  const [policyNumber, setPolicyNumber] = useRecoilState(policyNumberState);
  const [entityOfConcern, setEntityOfConcern] =
    useRecoilState(entityOfConcernState);
  const [claimType, setClaimType] = useRecoilState(claimTypeState);
  const [regulatedEntityIdentifier, setRegulatedEntityIdentifier] =
    useRecoilState(regulatedEntityIDState);
  const [description, setDescription] = useRecoilState(descriptionState);
  const [caseType, setCaseType] = useRecoilState(caseTypeState);
  const [claimTypeValue, setClaimTypeValue] =
    useRecoilState(claimTypeValueState);
  const nonLifeValues = claimTypeValues["Non-Life"];
  const lifeValues = claimTypeValues.life;
  const motorValues = claimTypeValues["Motor Compensation"];

  const [error, setError] = useState(false);

  const handleNext = () => {
    if (complaintType === "PETITION") {
      if (
        complaintType.trim().length === 0 ||
        entityOfConcern.trim().length === 0 ||
        regulatedEntityIdentifier.trim().length === 0 ||
        claimType.trim().length === 0 ||
        claimTypeValue.trim().length === 0 ||
        (claimTypeValue === "Other" && otherNatureOfClaim.trim().length === 0)
      ) {
        setError(true);
      } else {
        if (complaintType === "PETITION") {
          setStep(2);
        } else {
          // this moves the user straight to the document page
          setStep(3);
        }
      }
    } else {
      if (
        complaintType.trim().length === 0 ||
        caseType.trim().length === 0 ||
        description.trim().length === 0
      ) {
        setError(true);
      } else {
        if (complaintType === "PETITION") {
          setStep(2);
        } else {
          // this moves the user straight to the document page
          setStep(3);
        }
      }
    }
  };

  // Generate today's date
  const todayDate = `${new Date()?.getFullYear()}-${(
    "0" +
    (new Date()?.getMonth() + 1)
  ).slice(-2)}-${("0" + new Date()?.getDate()).slice(-2)}`;

  // fetch regulated entity
  const { data, error: entityError } = useQuery(FETCH_ENTITY);

  // console.log("entityError", entityError);

  return (
    <section>
      <div>
        <div>
          <h2 className="text-center font-heading text-2xl font-semibold text-gray-primary">
            Ticket Details
          </h2>
          <p className="my-6 text-center font-heading text-gray-primary">
            Enter details in this form to submit a complaint/petition
          </p>
          <div className="mt-10 flex flex-col items-center justify-between px-6 md:flex-row">
            <div className="mx-2 mt-10 flex w-full flex-col gap-y-[12px] md:mt-0">
              <label htmlFor="last_name">Date Of Incident</label>
              <InputComponent
                type={INPUTTYPES.date}
                name="date_of_incident"
                classNames="py-5"
                value={dateOfIncident}
                onChange={(e) => setDateOfIncident(e.currentTarget.value)}
                max={todayDate}
              />
            </div>
          </div>
          {complaintType === "PETITION" && (
            <>
              <div className="mt-10 flex flex-col items-center justify-between px-6 md:flex-row">
                <div className="mx-2 flex w-full flex-col gap-y-[12px]">
                  <label htmlFor="email">Policy Number</label>
                  <InputComponent
                    type={INPUTTYPES.text}
                    placeholder="Policy Number"
                    name="policy_number"
                    classNames="py-5 md:w-[21rem]"
                    value={policyNumber}
                    onChange={(e) => setPolicyNumber(e.currentTarget.value)}
                  />
                </div>
                <div className="mx-2 mt-10 flex w-full flex-col gap-y-[12px] md:mt-0">
                  <label htmlFor="claim_type">
                    Claim Type <span className="text-red-500">*</span>
                  </label>
                  <select
                    name=""
                    id=""
                    className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                    value={claimType}
                    onChange={(e) => setClaimType(e.target.value)}
                    placeholder="Choose Claim Type"
                    style={{
                      border: error && claimType === "" ? "1px solid red" : "",
                    }}
                  >
                    <option value="" disabled>
                      Choose Claim Type
                    </option>
                    <option value="life">Life</option>
                    <option value="nonLife">Non-Life</option>
                    <option value="motorCompensation">
                      Motor-Compensation
                    </option>
                  </select>
                </div>
              </div>

              <>
                <div className="flex flex-col items-center justify-between px-6 md:mt-10 md:flex-row">
                  <div className="mx-2 mt-10 flex w-full flex-col gap-y-[12px] md:mt-0">
                    <label htmlFor="first_name">
                      Entity Of Concern <span className="text-red-500">*</span>
                    </label>
                    <select
                      name=""
                      id=""
                      className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                      value={entityOfConcern}
                      onChange={(e) => {
                        setEntityOfConcern(e.target.value);
                        const index = e.target.selectedIndex;
                        const el = e.target.children[index];
                        const option = el.getAttribute("id")?.toString();
                        setRegulatedEntityIdentifier(option || "");
                      }}
                      placeholder="Choose Entity Of Concern"
                      style={{
                        border:
                          error && entityOfConcern === ""
                            ? "1px solid red"
                            : "",
                      }}
                    >
                      <option value="" disabled>
                        Choose Entity Of Concern
                      </option>

                      <option value="Entity Of Concern" disabled>
                        Entity Of Concern
                      </option>
                      {data &&
                        data.RegulatedEntity.filter((v: any) => {
                          if (claimType === "life") {
                            return v.type === "Life";
                          } else if (claimType === "nonLife") {
                            return v.type === "Non-Life";
                          } else {
                            return v;
                          }
                        })?.map((insurer: IRegulatedEntity) => (
                          <option
                            id={insurer.id}
                            key={insurer.id}
                            value={insurer.name}
                          >
                            {insurer.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="mx-2 mt-10 flex w-full flex-col gap-y-[12px] md:mt-0">
                    <label className="mb-2 text-sm" htmlFor="claim_type_value">
                      Nature of Claim <span className=" text-red-500">*</span>
                    </label>
                    <select
                      className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                      value={claimTypeValue}
                      onChange={(e) => setClaimTypeValue(e.target.value)}
                      style={{
                        border:
                          error && claimTypeValue === "" ? "1px solid red" : "",
                      }}
                    >
                      <option value="" disabled>
                        Choose Nature of Claim
                      </option>
                      {claimType === "life" ? (
                        <>
                          {lifeValues.map((entry, index) => (
                            <option key={index}>{entry}</option>
                          ))}
                        </>
                      ) : claimType === "nonLife" ? (
                        <>
                          {nonLifeValues.map((entry, index) => (
                            <option key={index}>{entry}</option>
                          ))}
                        </>
                      ) : (
                        <>
                          {motorValues.map((entry, index) => (
                            <option key={index}>{entry}</option>
                          ))}
                        </>
                      )}
                    </select>
                  </div>
                </div>

                {claimType === "motorCompensation" && (
                  <div className="mt-10 flex flex-col items-center justify-between px-6 md:flex-row">
                    <div className=" flex w-full flex-col  gap-y-[12px] md:mx-0 md:mt-0 md:w-[47%]">
                      <label htmlFor="vehicleNumber">Vehicle Number</label>
                      <InputComponent
                        type={INPUTTYPES.text}
                        placeholder="XXXXXXXXXXXXX"
                        name="vehicleNumber"
                        classNames="py-5"
                        value={vehicle_Num}
                        onChange={(e) => setVehicle_Num(e.currentTarget.value)}
                      />
                    </div>
                  </div>
                )}

                {/* When Nature of claim is other show this */}
                <div className="mt-10 flex items-center justify-between px-6 md:flex-row">
                  {claimTypeValue === "Other" && (
                    <div className="mx-2 flex w-full flex-col gap-y-[12px]">
                      <label htmlFor="email">
                        Other Nature of Claim{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      <InputComponent
                        type={INPUTTYPES.text}
                        placeholder="Type N/A if unknown"
                        name="otherNatureOfClaim"
                        classNames="py-5 md:w-[21rem]"
                        value={otherNatureOfClaim}
                        onChange={(e) =>
                          setOtherNatureOfClaim(e.currentTarget.value)
                        }
                        style={{
                          border:
                            error && otherNatureOfClaim === ""
                              ? "1px solid red"
                              : "",
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            </>
          )}
          {complaintType !== "PETITION" && (
            <div className="w-full">
              {/* CASE TYPE */}
              {complaintType === "MOTOR-PETITION" && (
                <div className="mt-10 flex max-w-[1100px] flex-col items-center justify-between gap-y-10 px-6 md:flex-row md:gap-y-0">
                  <div className="mx-2 flex w-[100%] flex-col gap-y-[12px]">
                    <label htmlFor="caseType">
                      Case Type <span className="text-red-500">*</span>
                    </label>
                    <select
                      name=""
                      id=""
                      className="w-full appearance-none rounded border border-gray-200 bg-gray-200 py-5 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                      value={caseType}
                      onChange={(e) => setCaseType(e.target.value)}
                      placeholder="Choose Case Type"
                      style={{
                        border: error && caseType === "" ? "1px solid red" : "",
                      }}
                    >
                      <option value="" disabled>
                        Choose Case Type
                      </option>
                      <option value="injury">Injury</option>
                      <option value="death">Death</option>
                    </select>
                  </div>

                  <div className=" flex w-full flex-col  gap-y-[12px] md:mx-0 md:mt-0 md:w-[47%]">
                    <label htmlFor="vehicleNumber">Vehicle Number</label>
                    <InputComponent
                      type={INPUTTYPES.text}
                      placeholder="XXXXXXXXXXXXX"
                      name="vehicleNumber"
                      classNames="py-5"
                      value={vehicle_Num}
                      onChange={(e) => setVehicle_Num(e.currentTarget.value)}
                    />
                  </div>
                </div>
              )}

              {/* DESCRIPTION */}
              <div className="mx-2 mt-10 w-full px-6">
                <div className="flex flex-col gap-y-[12px]">
                  <label htmlFor="email">
                    Description Of Complaint{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    placeholder="Description Of Petition"
                    className="min-h-[156px] w-full appearance-none rounded border border-gray-200 bg-gray-200 py-2 px-4 leading-tight text-gray-500 focus:border-purple-primary focus:bg-white focus:outline-none"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{
                      border:
                        error && description === "" ? "1px solid red" : "",
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mx-2 mt-[30%] mb-10 flex items-center justify-between px-6 sm:mt-[20%] md:mb-5">
          <div className="w-2/5 md:w-1/3">
            <Link to={"/complaint/single"}>
              <OutlineBtn>Previous</OutlineBtn>
            </Link>
          </div>
          <div className="w-2/5 md:w-1/3">
            <SolidBtn
              onClick={() => {
                handleNext();
              }}
            >
              Next
            </SolidBtn>
          </div>
        </div>
      </div>
    </section>
  );
}
