import React, { useState } from 'react';
import { OutlineBtn, SolidBtn } from '../../ui/buttons';

import { useRecoilState } from 'recoil';

import { descriptionState, stepState } from '../../../recoil/corporate';

export default function StepTwo() {
	const [step, setStep] = useRecoilState(stepState);
	const [description, setDescription] = useRecoilState(descriptionState);

	const [error, setError] = useState(false);

	const handleNext = () => {
		if (description.trim().length === 0) {
			setError(true);
		} else {
			setStep(3);
		}
	};

	return (
		<section>
			<section>
				<div className=''>
					<h2 className='text-gray-primary text-2xl font-heading font-semibold text-center'>
						Ticket Details
					</h2>
					<p className='text-gray-primary font-heading my-6 text-center'>
						Enter details in this form to submit a complaint/petition
					</p>
					<div className='mt-10 w-full'>
						<div className='flex flex-col gap-y-[12px]'>
							<label htmlFor='description_of_complaint'>
								Description Of Complaint <span className='text-red-500'>*</span>
							</label>
							<textarea
								placeholder='Description Of Complaint'
								className='bg-gray-200 appearance-none border border-gray-200 rounded w-full py-2 px-4 text-gray-500 leading-tight focus:outline-none focus:bg-white focus:border-purple-primary min-h-[240px]'
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								style={{
									border: error && description === '' ? '1px solid red' : '',
								}}
							></textarea>
						</div>
					</div>
				</div>
				<div className='mt-[30%] flex justify-between items-center'>
					<div className='w-2/5 md:w-1/3'>
						<OutlineBtn onClick={() => setStep(step - 1)}>Previous</OutlineBtn>
					</div>
					<div className='w-2/5 md:w-1/3'>
						<SolidBtn onClick={handleNext}>Next</SolidBtn>
					</div>
				</div>
			</section>
		</section>
	);
}
