import { atom } from "recoil";

export const stepState = atom<number>({
  key: "stepIndividual",
  default: 1,
});

export const nameState = atom<string>({
  key: "firstName",
  default: "",
});
export const firstNameState = atom<string>({
  key: "firstName",
  default: "",
});

export const lastNameState = atom<string>({
  key: "lastName",
  default: "",
});

export const emailState = atom<string>({
  key: "email",
  default: "",
});

export const phoneNumberState = atom<string>({
  key: "phoneNumber",
  default: "",
});

export const residentialAddressState = atom<string>({
  key: "residentialAddress",
  default: "",
});

export const digitalAddressState = atom<string>({
  key: "digitalAddress",
  default: "",
});
export const ghanaCardState = atom<string>({
  key: "ghanaCard",
  default: "",
});

export const complaintTypeState = atom<string>({
  key: "complaintTypeIndividual",
  default: "",
});

export const dateOfIncidentState = atom<string>({
  key: "dateOfIncidentIndividual",
  default: "",
});

export const descriptionState = atom<string>({
  key: "descriptionIndividual",
  default: "",
});

export const entityOfConcernState = atom<string>({
  key: "entityOfConcernIndividual",
  default: "",
});

export const claimTypeState = atom<string>({
  key: "claimTypeIndividual",
  default: "",
});

export const claimTypeValueState = atom<string>({
  key: "valueClaimTypeIndividual",
  default: "",
});

export const caseTypeState = atom<string>({
  key: "caseType",
  default: "",
});

export const policyNumberState = atom<string>({
  key: "policyNumberIndividual",
  default: "",
});

export const complainantID = atom<string>({
  key: "complianantID",
  default: "",
});

export const regulatedEntityIDState = atom<string>({
  key: "regulatedEntityIdentifier",
  default: "",
});
export const ticketId = atom<string>({
  key: "ticketIdIndividual",
  default: "",
});
export const ticketNumber = atom<string>({
  key: "ticketNumber",
  default: "",
});

// this state holds the currently uploaded files in
// the browser. it is different from the one below
export const selectedDocumentsState = atom<File[]>({
  key: "selectedDocumentsIndividual",
  default: [],
});

// this state holds files returned from the
// server upon successful upload
export const uploadedFilesState = atom<{ id: string; fileName: string }[]>({
  key: "uploadedFilesIndividual",
  default: [],
});

export const filesToBeUploadedState = atom<
  { id: string | number; fileName: string; file: File }[]
>({
  key: "uploadingFiles",
  default: [],
});

export const ghanaIDState = atom<string>({
  key: "ghanaID",
  default: "",
});

export const nationalIdentificationTypeState = atom<string>({
  key: "IDTYPE",
  default: "",
});

export const userRegionState = atom<string>({
  key: "regionOfUser",
  default: "",
});

export const chosenRegionState = atom<string>({
  key: "individualChosenRegion",
  default: "",
});

export const otherNatureOfClaimState = atom<string>({
  key: "otherClaimNature",
  default: "",
});

export const numVehicleState = atom<string>({
  key: "vehicle_num_k",
  default: "",
});
