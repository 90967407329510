export const SolidBtn = (props: any) => {
	return (
		<button
			onClick={props.onClick}
			className={`${
				props.classNames
			} bg-purple-primary text-white font-semibold rounded px-3 md:px-6 py-3 w-full ${
				props.disabled && 'cursor-not-allowed'
			}`}
			disabled={props.disabled}
		>
			{props.children}
		</button>
	);
};

export const OutlineBtn = (props: any) => {
	return (
		<button
			onClick={props.onClick}
			className={`${props.classNames} bg-transparent border-[2px] border-solid border-purple-primary rounded text-purple-primary font-semibold px-3 py-2 w-full`}
		>
			{props.children}
		</button>
	);
};

export const DashedBtn = (props: any) => {
	return (
		<button
			onClick={props.onClick}
			className={`${props.classNames} btn-dashed bg-transparent border-[2px] border-purple-primary border-dashed rounded text-purple-primary font-semibold px-14 py-3`}
		>
			{props.children}
		</button>
	);
};
