/* eslint-disable no-console */
import { useRecoilValue } from "recoil";
import { useQuery } from "@apollo/client";
import { SolidBtn } from "../../ui/buttons";
import { useNavigate } from "react-router-dom";
import { ticketIdState } from "../../../recoil/corporate";
import { FETCH_TICKET_NUMBER } from "../../../graphql/queries";
import { useEffect } from "react";

export default function StepFive() {
  const ticketIdentify = useRecoilValue(ticketIdState);
  const navigate = useNavigate();

  // fetch ticket number here
  const { data, refetch } = useQuery(FETCH_TICKET_NUMBER, {
    variables: {
      id: ticketIdentify,
    },
    onCompleted(data) {},
  });

  // This function clears the whole app state and redirects the user back to the Home Page
  const redirect = () => {
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    const pollInterval = 2000; // 2 seconds

    const pollingInterval = setInterval(() => {
      refetch();
    }, pollInterval);

    return () => clearInterval(pollingInterval); // Clean up the interval when the component is unmounted
  }, []); // Empty dependency array means this effect runs once when the component mounts
  return (
    <section className="flex-center my-20 flex max-w-[630px] flex-col items-center gap-y-[45px] px-6 text-center">
      <img
        src="/assets/images/checkmark.svg"
        height={30}
        width={30}
        alt="success checkmark"
      />
      <p className="leading-6">
        Your complaint with ticket number
        <span className="font-bold">{" "}
          {data && data?.nic_ccms_Complaint_by_pk?.ticketNumber}
        </span>{" "}
        has been successfully submitted. You should expect notification in your
        email and text messages with the next way forward
      </p>
      <SolidBtn onClick={redirect}>Done</SolidBtn>
    </section>
  );
}
