interface IPrevInfo {
  headerInfo: string;
  subInfo: string;
  flexProp?: string;
}
const PreviewInfoDisplay = (props: IPrevInfo) => {
  return (
    <div
      className={
        `${props.flexProp === "col"}` ? "mb-[10px] flex flex-col" : "mb-[10px]"
      }
    >
      <h4>{props.headerInfo}</h4>
      <p className="text-sm font-semibold text-gray-primary">
        {props.subInfo.length !== 0 ? props.subInfo : "N/A"}
      </p>
    </div>
  );
};

export { PreviewInfoDisplay };
